import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Dimensions, View, StyleSheet } from "react-native-web";
import { colors } from "../../styles/colors";
import axios from "axios";
import { useParams } from "react-router-dom";
import axios_reports from "../../axios/axios-reports";
import { VictoryLine, VictoryTheme, VictoryChart, VictoryAxis } from "victory";
import LoadingDisplay from "../../components/LoadingDisplay";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work

import { DatePicker, Divider, message, Select, Typography } from "antd";
import "antd/dist/antd.css";
import { unstable_batchedUpdates } from "react-dom";
import DownTimes from "./components/DownTimes";
moment.locale("hr");

let { width, height } = Dimensions.get("screen");

const { RangePicker, WeekPicker, MonthPicker, YearPicker } = DatePicker;

const Machine = ({ toggleDrawer, set_toggleDrawer }) => {
  const { id } = useParams();
  const [loading, set_loading] = useState(false);
  const [format, set_format] = useState("DD/MM");
  const [data, set_data] = useState([]);
  const [avg_speed, set_avg_speed] = useState();
  const [pickerType, set_pickerType] = useState("last");
  const [last, set_last] = useState("3_hours");
  const [filters, set_filters] = useState({
    shift: undefined,
    start: moment().subtract(3, "hours").toISOString(),
    end: moment().toISOString(),
    device: id,
  });

  const isDayDiff = (start, end) => {
    let s = moment(start);
    let e = moment(end);
    let r = e.diff(s, "hours");
    if (r < 72) {
      return true;
    }

    return false;
  };

  const getData = async (filters) => {
    if (isDayDiff(filters.start, filters.end)) {
      set_format("HH:mm");
    } else {
      set_format("DD/MM");
    }
    set_loading(true);
    let token = await localStorage.getItem("token");
    axios_reports({ token })
      .get(`graph/speed/`, {
        params: filters,
      })
      .then((res) => {
        set_avg_speed(res.data.avg_speed);
        set_data(
          res.data.values.map((x) => ({
            x: x[0],
            y: x[1],
          }))
        );
        set_filters(filters);
        set_loading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Dogodila se greska.");
        set_loading(false);
      });
  };

  useEffect(() => {
    getData(filters);
  }, [id]);

  const generatePicker = () => {
    if (pickerType === "last") {
      return (
        <Select
          value={last}
          size="large"
          style={{ width: "100%" }}
          placeholder="Odaberi interval"
          format="DD.MM.YYYY"
          onSelect={(val) => {
            unstable_batchedUpdates(() => {
              set_last(val);
              let hours = val.split("_")[0];
              getData({
                ...filters,
                start: moment().subtract(+hours, "hours").seconds(0).milliseconds(0).toISOString(),
                end: moment().seconds(0).milliseconds(0).toISOString(),
              });
            }, []);
          }}
        >
          <Select.Option value={"3_hours"}>Zadnja 3 sata</Select.Option>
          <Select.Option value={"6_hours"}>Zadnjih 6 sati</Select.Option>
          <Select.Option value={"12_hours"}>Zadnjih 12 sati</Select.Option>
          <Select.Option value={"24_hours"}>Zadnjih 24 sata</Select.Option>
        </Select>
      );
    } else if (pickerType === "week") {
      return (
        <WeekPicker
          size="large"
          style={{ width: "100%" }}
          value={moment(filters.start)}
          key={filters.start}
          allowClear={false}
          format="DD.MM.YYYY"
          inputReadOnly={true}
          onSelect={(val) => {
            getData({
              ...filters,
              start: moment(val).startOf("week").toISOString(),
              end: moment(val).endOf("week").toISOString(),
            });
          }}
        />
      );
    } else if (pickerType === "day") {
      return (
        <DatePicker
          size="large"
          style={{ width: "100%" }}
          value={moment(filters.start)}
          key={filters.start}
          allowClear={false}
          format="DD.MM.YYYY"
          inputReadOnly={true}
          onSelect={(val) => {
            getData({
              ...filters,
              start: moment(val).startOf("day").toISOString(),
              end: moment(val).endOf("day").toISOString(),
            });
          }}
        />
      );
    } else if (pickerType === "month") {
      return (
        <MonthPicker
          size="large"
          style={{ width: "100%" }}
          value={moment(filters.start)}
          key={filters.start}
          allowClear={false}
          format="DD.MM.YYYY"
          inputReadOnly={true}
          onSelect={(val) => {
            getData({
              ...filters,
              start: moment(val).startOf("month").toISOString(),
              end: moment(val).endOf("month").toISOString(),
            });
          }}
        />
      );
    } else if (pickerType === "year") {
      return (
        <YearPicker
          size="large"
          style={{ width: "100%" }}
          value={moment(filters.start)}
          key={filters.start}
          format="DD.MM.YYYY"
          allowClear={false}
          inputReadOnly={true}
          onSelect={(val) => {
            getData({
              ...filters,
              start: moment(val).startOf("year").toISOString(),
              end: moment(val).endOf("year").toISOString(),
            });
          }}
        />
      );
    } else if (pickerType === "range") {
      return (
        <RangePicker
          size="large"
          style={{ width: "100%" }}
          value={[moment(filters.start), moment(filters.end)]}
          defaultPickerValue={[moment(filters.start), moment(filters.end)]}
          key={filters.start}
          allowClear={false}
          format="DD.MM.YYYY"
          inputReadOnly={true}
          onChange={(date, stringDate) => {
            if (!date) {
              return;
            }
            getData({
              ...filters,
              start: date[0].toISOString(),
              end: date[1].toISOString(),
            });
          }}
        />
      );
    }
  };

  return (
    <View style={styles.container}>
      {loading && <LoadingDisplay />}
      <Header name={id} canGoBack={true} toggleDrawer={toggleDrawer} set_toggleDrawer={set_toggleDrawer} />
      <div style={{ padding: "0 6px", height: "calc(100vh - 99px)", overflowY: "scroll", width: "100%" }}>
        <div style={{ backgroundColor: "white", width: "100%", minHeight: "33vh" }}>
          {data.length > 0 ? (
            <VictoryChart style={{ width: "100%" }} theme={VictoryTheme.material}>
              <VictoryAxis
                tickFormat={(x) => moment(x).format(format)}
                fixLabelOverlap={true}
                style={{ tickLabels: { angle: 0, fontSize: 9, marginTop: 24 } }}
              />

              <VictoryAxis dependentAxis fixLabelOverlap={true} />

              <VictoryLine
                data={data}
                theme={VictoryTheme.material}
                interpolation="catmullRom"
                style={{
                  width: "100%",
                  data: { stroke: colors.darkGrey, strokeWidth: 1, strokeLinecap: "round" },
                  parent: { border: "1px solid #ccc" },
                }}
              />
            </VictoryChart>
          ) : (
            <h1 style={{ textAlign: "center", margin: "20px" }}>Nema podataka</h1>
          )}
        </div>
        <Divider style={{ marginTop: 6, marginBottom: 30 }} />
        <div className="spaceBetweenRow" style={{ alignItems: "center", marginBottom: 12 }}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            Prosječna brzina stroja:
          </Typography.Title>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {avg_speed}
          </Typography.Title>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "top",
            width: "100%",
            flexWrap: "wrap",
            padding: "0 6px",
          }}
        >
          <div style={{ width: "100%" }}>{generatePicker()}</div>
        </div>
        <div
          style={{
            padding: "0 6px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: 16,
            gap: 16,
          }}
        >
          <Select
            size="large"
            value={filters.shift}
            style={{ width: "50%", color: "black", borderColor: "black" }}
            placeholder="Odaberi smjenu"
            allowClear
            onClear={() => {
              getData({ ...filters, shift: undefined });
            }}
            onSelect={(val) => {
              getData({ ...filters, shift: val });
            }}
          >
            <Select.Option value={"1"}>Prva smjena</Select.Option>
            <Select.Option value={"2"}>Druga smjena</Select.Option>
            <Select.Option value={"3"}>Treca smjena</Select.Option>
          </Select>
          <Select
            size="large"
            allowClear
            style={{ width: "50%" }}
            value={pickerType}
            onSelect={(val) => {
              set_pickerType(val);
              if (val === "last") {
                let hours = last.split("_")[0];
                getData({
                  ...filters,
                  start: moment().subtract(+hours, "hours").toISOString(),
                  end: moment().toISOString(),
                });
              } else if (val === "range") {
                getData(filters);
              } else {
                console.log(moment().startOf(val).toISOString(), moment().endOf(val).toISOString());
                getData({
                  ...filters,
                  start: moment().startOf(val).toISOString(),
                  end: moment().endOf(val).toISOString(),
                });
              }
              // set_filters({...filters, shift: val})
            }}
          >
            <Select.Option value={"last"}>Zadnjih</Select.Option>
            <Select.Option value={"week"}>Tjedan</Select.Option>
            <Select.Option value={"day"}>Dan</Select.Option>
            {/* <Select.Option value={"month"}>Mjesec</Select.Option>
            <Select.Option value={"year"}>Godina</Select.Option>
            <Select.Option value={"range"}>Raspon</Select.Option> */}
          </Select>
        </div>
        <Divider />
        <DownTimes start={filters.start} end={filters.end} shift={filters.shift} />
      </div>
    </View>
  );
};

export default Machine;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingTop: 30,
    backgroundColor: colors.white,
    alignItems: "center",
  },

  contentContainerStyle: {
    alignItems: "center",
    height: "72vh",
  },
  text: {
    fontSize: 24,
    marginTop: 4,
    fontWeight: 500,
    fontFamily: "Poppins",
    textAlign: "center",
  },
  pressable: {
    width: 100,
    height: 40,
    backgroundColor: colors.lightGrey,
  },
  flexRow: {
    width: width - 40,
    marginHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
