import axios from "axios";

import { getApiEndpoint } from "./endpoints";

const instance = (options) => {

  return axios.create({
    baseURL: getApiEndpoint() + "accounts/",
    timeout: 6000,
  });
};

export default instance;
