import { put, call, takeEvery, takeLatest } from "redux-saga/effects";

import axios_reports from "../../../axios/axios-reports";

import { getQuarter } from "../../../helpers/Dates";

import * as actions from "./actions";
import { LOGOUT } from "../Users/actions";

const storageUser = async () => {
  let tmp = await localStorage.getItem("user");
  return JSON.parse(tmp);
};

const initialState = {
  reports: {
    data: {
      production: {
        plan: { months: {} },
        reports: {
          "1": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
          "2": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
          "3": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
          "4": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
          "5": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
          "6": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
          "7": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
          "8": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
          "9": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
          "10": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
          "11": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
          "12": {
            KILOMETRI_UKUPNO: 0,
            KILOMETRI_UKUPNO_AL: 0,
            KILOMETRI_UKUPNO_CU: 0,
            KILOMETRI_UKUPNO_EN: 0,
            KILOMETRI_UKUPNO_IN: 0,
            MASAP_SASTAVNICA_AL: 0,
            MASAP_SASTAVNICA_CU: 0,
            MASAP_SASTAVNICA_EN: 0,
            MASAP_SASTAVNICA_IN: 0,
            MASAP_SASTAVNICA_UKUPNO: 0,
            MASA_VAGANO: 0,
            MASA_VAGANO_AL: 0,
            MASA_VAGANO_CU: 0,
            MASA_VAGANO_EN: 0,
            MASA_VAGANO_IN: 0,
          },
        },
      },
      sales: {
        plan: { months: {} },
        reports: {
          "1": {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
          "2": {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
          "3": {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
          "4": {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
          "5": {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
          "6": {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
          7: {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
          "8": {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
          "9": {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
          "10": {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
          "11": {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
          "12": {
            PLANIRANA_NABAVNA_VR: 0,
            PLANIRANI_RUC: 0,
            PRODAJNA_VRIJEDNOST: 0,
            PRODAJNA_VRIJEDNOST_AL: 0,
            PRODAJNA_VRIJEDNOST_CU: 0,
            PRODAJNA_VRIJEDNOST_EN: 0,
            PRODAJNA_VRIJEDNOST_IN: 0,
            PRODAJNA_VRIJEDNOST_PVC: 0,
            PRODANE_TONE: 0,
            PRODANI_KILOMETRI: 0,
            STVARNI_RUC: 0,
          },
        },
      },
      companies: [],
    },
    status: "",
    selected_year: new Date().getFullYear(),
  },

  active_params: {
    quarter: getQuarter(),
    month: new Date().getMonth() + 1,
    active_tab: "months",
    year: new Date().getFullYear(),
    shadowIndex: new Date().getMonth(),
  },

  savePlan: { data: {}, status: "" },
  errorRequestVisible: false,

  saveSalesPlan: { data: {}, status: "" },

  maintenances: {
    data: [],
    status: "",
  },
};

export default function reducer(state = initialState, action = {}) {
  var tmp = {};
  var index = -1;

  switch (action.type) {
    case actions.SET_ERROR_REQUEST_VISIBLE:
      return {
        ...state,
        errorRequestVisible: true,
      };

    case actions.SET_ERROR_REQUEST_NOT_VISIBLE:
      return {
        ...state,
        errorRequestVisible: false,
      };

    case actions.SET_ACTIVE_TAB: {
      return {
        ...state,
        active_params: {
          ...action.data,
        },
      };
    }

    case actions.GET_REPORTS:
      return {
        ...state,
        reports: {
          status: "loading",
          data: state.reports.data,
          selected_year: action.year,
        },
      };

    case actions.GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          status: "",
          data: action.data,
          selected_year: action.year,
        },
      };

    case actions.GET_REPORTS_FAIL:
      return {
        ...state,
        reports: {
          status: "error",
          data: state.reports.data,
          selected_year: state.reports.selected_year,
        },
      };

    case actions.SET_P_PLAN:
      return {
        ...state,
        savePlan: {
          data: {},
          status: "loading",
        },
      };

    case actions.SET_P_PLAN_SUCCESS:
      tmp = { ...state.reports.data };

      tmp.production.plan = action.data;

      return {
        ...state,
        reports: { data: { ...tmp }, status: "" },

        savePlan: {
          data: {},
          status: "",
        },
      };

    case actions.SET_P_PLAN_FAIL:
      return {
        ...state,
        savePlan: {
          data: {},
          status: "",
        },
      };

    case actions.SET_S_PLAN:
      return {
        ...state,
        saveSalesPlan: {
          data: {},
          status: "loading",
        },
      };
    case actions.SET_S_PLAN_SUCCESS:
      tmp = { ...state.reports.data };

      tmp.sales.plan = action.data;

      return {
        ...state,
        reports: { data: { ...tmp }, status: "" },

        saveSalesPlan: {
          data: {},
          status: "",
        },
      };
    case actions.SET_S_PLAN_FAIL:
      return {
        ...state,
        saveSalesPlan: {
          data: {},
          status: "",
        },
      };

    case actions.SET_MAINTENANCES:
      return {
        ...state,
        maintenances: {
          data: [],
          statu: "loading",
        },
      };

    case actions.SET_MAINTENANCES_SUCCESS:
      return {
        ...state,
        maintenances: {
          data: action.data,
          status: "",
        },
      };

    case actions.SET_MAINTENANCES_FAIL: {
      return {
        ...state,
        maintenances: {
          data: [],
          status: "error",
        },
      };
    }

    default:
      return state;
  }
}

function transformData(data) {
  return data.data;
}

// getReports
function _getReports(options) {
  return axios_reports(options).get("get_data_by_year/", {
    params: options.queryParams,
  });
}

export function* watcherGetReports() {
  yield takeLatest(actions.GET_REPORTS, getReports);
}

function* getReports(payload) {
  try {
    const user = yield call(storageUser);
    const options = {};
    options.queryParams = payload.queryParams;
    options.token = user.token;

    const response = yield call(_getReports, options);

    const data = transformData(response);

    yield put({
      type: actions.GET_REPORTS_SUCCESS,
      data,
      year: payload.queryParams.year,
    });

    yield put({ type: actions.SET_ERROR_REQUEST_NOT_VISIBLE });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);

    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    yield put({ type: actions.SET_ERROR_REQUEST_VISIBLE });

    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_REPORTS_FAIL, error: error.response });
  }
}
// End reports

// set production
function _setProductionPlan(options) {
  return axios_reports(options).post(
    "add_update_production_plan/",
    options.data
  );
}

export function* watcher_setProductionPlan() {
  yield takeEvery(actions.SET_P_PLAN, setProductionPlan);
}

function* setProductionPlan(payload) {
  try {
    const user = yield call(storageUser);
    const options = {};
    options.data = payload.data;
    options.token = user.token;
    const response = yield call(_setProductionPlan, options);

    const data = transformData(response);

    yield put({
      type: actions.SET_P_PLAN_SUCCESS,
      data,
      year: payload.data.year,
    });
    yield put({ type: actions.SET_ERROR_REQUEST_NOT_VISIBLE });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);

    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    yield put({ type: actions.SET_ERROR_REQUEST_VISIBLE });

    // dispatch a failure action to the store with the error
    yield put({ type: actions.SET_P_PLAN_FAIL, error: error.response });
  }
}
// End

// set sales
function _setSalesPlan(options) {
  return axios_reports(options).post("add_update_sales_plan/", options.data);
}

export function* watcher_setSalesPlan() {
  yield takeEvery(actions.SET_S_PLAN, setSalesPlan);
}

function* setSalesPlan(payload) {
  try {
    const user = yield call(storageUser);
    const options = {};
    options.data = payload.data;
    options.token = user.token;
    const response = yield call(_setSalesPlan, options);

    const data = transformData(response);

    yield put({
      type: actions.SET_S_PLAN_SUCCESS,
      data,
      year: payload.data.year,
    });

    yield put({ type: actions.SET_ERROR_REQUEST_NOT_VISIBLE });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);

    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    yield put({ type: actions.SET_ERROR_REQUEST_VISIBLE });

    // dispatch a failure action to the store with the error
    yield put({ type: actions.SET_S_PLAN_FAIL, error: error.response });
  }
}
// End
