import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  Text,
  StatusBar,
  Platform,
} from "react-native";
import { useHistory } from "react-router-dom";

import * as calculate from "../../helpers/calculate";

// Components
import Header from "../../components/Header";
import DateSlider from "../../components/DateSlider";
import DateTab from "../../components/DateTab";
import Button from "./components/Button";
import ErrorRequestModal from "../../components/ErrorRequestModal";

import LoadingDisplay from "../../components/LoadingDisplay";

// Styles
import { colors } from "../../styles/colors";

import Information from "../../components/Information";

import { useSelector } from "react-redux";

// Constants
let { width, height } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}

export default function Production({ toggleDrawer, set_toggleDrawer }) {
  // Variables

  const {
    reports: {
      data: {
        production: {
          reports,
          plan: { months },
        },
      },
      status,
    },
    active_params,
  } = useSelector((state) => state.Reports);
  const scrollY = 0;
  const history = useHistory();
  const [tab, setTab] = useState(active_params.active_tab);
  const [month, setMonth] = useState(active_params.month);
  const [quarter, setQuarter] = useState(active_params.quarter);
  const [year, setYear] = useState(active_params.year);

  // Methods


  const render_data = calculate.production(
    active_params,
    reports,
    months || {}
  );


  const _loadingDisplay = status === "loading" && <LoadingDisplay />;

  const last_update = () => {
    if (active_params.active_tab == 'months') {
      if (reports[active_params.month].timestamp != null) {
        let ts = reports[active_params.month].timestamp
        let d = ts.split("T")[0]
        let t = ts.split("T")[1].split(".")[0]
        let year = d.split("-")[0]
        let month = d.split("-")[1]
        let day = d.split("-")[2]
        let hours = t.split(":")[0]
        let minutes = t.split(":")[1]
        return day + "." + month + "." + year + ". " + hours + ":" + minutes
      }
      return ' - '
    }
    return null
  };

  const show_last_update = () => {
    if (last_update() == null) {
      return
    }
    return (
      <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 15, fontFamily: "Poppins", color: 'rgba(0,0,0,0.5)' }}
            >
              Ažurirano: {last_update()}
            </Text>
          </View>
    )
  }

  return (
    <>
      <StatusBar backgroundColor={colors.black} />
      <View style={styles.container}>
        {_loadingDisplay}
        {/* Header */}
        <Header
          name="Pregled proizvodnje"
          toggleDrawer={toggleDrawer}
          set_toggleDrawer={set_toggleDrawer}
        />
        {/* Date Slider */}
        <DateSlider
          tab={tab}
          setMonth={setMonth}
          setQuarter={setQuarter}
          setYear={setYear}
          scrollY={scrollY}
        />

        {/* Wrapper */}
        <View style={webStyle.wrapper}>
          {/* Date Tab */}
          <DateTab
            activeTab={tab}
            setTab={setTab}
            month={month}
            quarter={quarter}
            year={year}
          />

          <View style={styles.flexRow}>
            <Button
              label="Planirano za proizvest"
              value={parseFloat(render_data.plan.quantity).toFixed(1) + " tona"}
              color={colors.orange}
              onPress={() => history.push("formproduction")}
            />

            <Button
              label="Planiran broj radnih dana"
              value={render_data.plan.planned_workingDays + " dana"}
              color={colors.green}
              onPress={() => history.push("formproduction")}
            />
          </View>

          {show_last_update()}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 21, fontWeight: 600, fontFamily: "Poppins" }}
            >
              Ukupno
            </Text>
          </View>
          <View>
            <Information
              value={render_data.model_of_sum["MASAP_SASTAVNICA_UKUPNO"]}
              label={"Normativ"}
              calculation={calculate.massMaterialsTotal(
                +render_data.plan.quantity,
                render_data.model_of_sum["MASAP_SASTAVNICA_UKUPNO"]
              )}
            />
            <Information
              value={render_data.model_of_sum["MASA_VAGANO"]}
              label={"Vagano"}
              calculation={calculate.massMaterialsTotal(
                render_data.plan.quantity,
                render_data.model_of_sum["MASA_VAGANO"]
              )}
            />
            <Information
              value={
                render_data.model_of_sum["MASAP_SASTAVNICA_UKUPNO"] -
                render_data.model_of_sum["MASA_VAGANO"]
              }
              label={"Razlika"}
              calculation={calculate.calculateRazlika(
                render_data.model_of_sum["MASAP_SASTAVNICA_UKUPNO"] -
                  render_data.model_of_sum["MASA_VAGANO"],
                render_data.model_of_sum["MASAP_SASTAVNICA_UKUPNO"]
              )}
            />
            <Information
              value={render_data.model_of_sum["KILOMETRI_UKUPNO"]}
              label={"Duljina"}
              calculation={null}
            />
            <Information
              value={calculate.getByWorkingDays(
                render_data.model_of_sum["MASAP_SASTAVNICA_UKUPNO"],
                render_data.plan.working_days,
                month
              )}
              label={"Dnevni prosjek"}
              calculation={null}
            />
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 21, fontWeight: 600, fontFamily: "Poppins" }}
            >
              Energetika
            </Text>
          </View>
          <View>
            <Information
              value={render_data.model_of_sum["MASAP_SASTAVNICA_EN"]}
              label={"Normativ"}
              calculation={null}
            />
            <Information
              value={render_data.model_of_sum["MASA_VAGANO_EN"]}
              label={"Vagano"}
              calculation={null}
            />
            <Information
              value={
                render_data.model_of_sum["MASA_VAGANO_EN"] -
                render_data.model_of_sum["MASAP_SASTAVNICA_EN"]
              }
              label={"Razlika"}
              calculation={null}
            />
            <Information
              value={render_data.model_of_sum["KILOMETRI_UKUPNO_EN"]}
              label={"Duljina"}
              calculation={null}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 21, fontWeight: 600, fontFamily: "Poppins" }}
            >
              Instalacije
            </Text>
          </View>
          <View>
            <Information
              value={render_data.model_of_sum["MASAP_SASTAVNICA_IN"]}
              label={"Normativ"}
              calculation={null}
            />
            <Information
              value={render_data.model_of_sum["MASA_VAGANO_IN"]}
              label={"Vagano"}
              calculation={null}
            />
            <Information
              value={
                render_data.model_of_sum["MASA_VAGANO_IN"] -
                render_data.model_of_sum["MASAP_SASTAVNICA_IN"]
              }
              label={"Razlika"}
              calculation={null}
            />
            <Information
              value={render_data.model_of_sum["KILOMETRI_UKUPNO_IN"]}
              label={"Duljina"}
              calculation={null}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 21, fontWeight: 600, fontFamily: "Poppins" }}
            >
              Bakar
            </Text>
          </View>
          <View>
            <Information
              value={render_data.plan.cu  *1000}
              label={"Plan"}
              calculation={null}
            />
            <Information
              value={render_data.model_of_sum["MASAP_SASTAVNICA_CU"]}
              label={"Normativ"}
              calculation={null}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 21, fontWeight: 600, fontFamily: "Poppins" }}
            >
              Aluminij
            </Text>
          </View>
          <View>
            <Information
              value={render_data.plan.al * 1000}
              label={"Plan"}
              calculation={null}
            />
            <Information
              value={render_data.model_of_sum["MASAP_SASTAVNICA_AL"]}
              label={"Normativ"}
              calculation={null}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 21, fontWeight: 600, fontFamily: "Poppins" }}
            >
              PVC
            </Text>
          </View>
          <View>
            <Information
              value={render_data.plan.pvc * 1000}
              label={"Plan"}
              calculation={null}
            />
            <Information
              value={render_data.model_of_sum["MASAP_SASTAVNICA_PVC"]}
              label={"Normativ"}
              calculation={null}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 21, fontWeight: 600, fontFamily: "Poppins" }}
            >
              XLPE
            </Text>
          </View>
          <View>
            <Information
              value={render_data.plan.xlpe * 1000} // ne pitaj zasto množenje
              label={"Plan"}
              calculation={null}
            />
            <Information
              value={render_data.model_of_sum["MASAP_SASTAVNICA_XLPE"]}
              label={"Normativ"}
              calculation={null}
            />
          </View>

          {/* Padding bottom */}
          <View style={{ height: 200 }} />
        </View>

        <ErrorRequestModal />
      </View>
    </>
  );
}

const webStyle = {
  wrapper: {
    overflow: "scroll",
    height: "99vh",
    // width,
    // flexShrink: 1,
    // flexGrow: 1,
    // flexBasis: "auto",
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 20,
    paddingBottom: 20 + 30,
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingTop: 30,
    backgroundColor: colors.black,
    alignItems: "center",
  },

  contentContainerStyle: {
    alignItems: "center",
    height: "72vh",
  },
  text: {
    fontSize: 24,
    marginTop: 4,
    fontWeight: 500,
    fontFamily: "Poppins",
    textAlign: "center",
  },
  pressable: {
    width: 100,
    height: 40,
    backgroundColor: colors.lightGrey,
  },
  flexRow: {
    width: width - 40,
    marginHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
