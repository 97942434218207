import React from "react";
import { Image, View, Text, StyleSheet } from "react-native";

import TimeTicking from "./TimeTicking";
import IMG_PAUSE from "../../../../assets/icons/pause.png";
import IMG_PLAY from "../../../../assets/icons/play.png";

const OrderTimeView = (props) => {
  if (props.status === "waiting") {
    return (
      <View style={styles.mainView}>
        <View style={styles.itemView}>
          <Text style={styles.orderStatusText}>Nalog pauziran!</Text>
          <Image style={styles.orderStatusIcon} source={IMG_PAUSE} />
        </View>
        <TimeTicking realStart={props.real_start} />
      </View>
    );
  }

  return (
    <View style={styles.mainView}>
      <View style={styles.itemView}>
        <Text style={styles.orderStatusText}>Nalog u tijeku!</Text>
        <Image style={styles.orderStatusIcon} src={IMG_PLAY} />
      </View>
      <TimeTicking realStart={props.real_start} />
    </View>
  );
};

const styles = {
  mainView: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 20,
  },
  itemView: {
    flexDirection: "row",
  },
  orderStatusText: {
    fontWeight: 400,
    opacity: 0.6,
    fontFamily: 'Poppins',
  },
  orderStatusIcon: {
    width: 32,
    height: 32,
    marginRight: -10,
    marginLeft: 0,
    marginTop: -7,
  },
};

export default OrderTimeView;
