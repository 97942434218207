import { View } from "antd-mobile";
import React, { useState } from "react";
import { Dimensions, StatusBar, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DateSlider from "../../components/DateSlider";
import DateTab from "../../components/DateTab";
import ErrorRequestModal from "../../components/ErrorRequestModal";
import Header from "../../components/Header";
import LoadingDisplay from "../../components/LoadingDisplay";
import { calculateCompanies } from "../../helpers/calculate";
import { colors } from "../../styles/colors";
import CompanyCard from "./components/CompanyCard";

// Constants
let { width, height } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}

const Companies = ({ toggleDrawer, set_toggleDrawer }) => {
  const {
    reports: {
      data: { companies },
      status,
    },
    active_params,
  } = useSelector((state) => state.Reports);

  const scrollY = 0;
  const history = useHistory();
  const [tab, setTab] = useState(active_params.active_tab);
  const [month, setMonth] = useState(active_params.month);
  const [quarter, setQuarter] = useState(active_params.quarter);
  const [year, setYear] = useState(active_params.year);

  const _loadingDisplay = status === "loading" && <LoadingDisplay />;

  const calculatedData = calculateCompanies(active_params, companies || []);

  return (
    <div>
      <ErrorRequestModal />

      <StatusBar backgroundColor={colors.black} />
      <View style={webStyle.container}>
        {_loadingDisplay}
        <Header
          name="Kompanije"
          toggleDrawer={toggleDrawer}
          set_toggleDrawer={set_toggleDrawer}
        />
        <DateSlider
          tab={tab}
          setMonth={setMonth}
          setQuarter={setQuarter}
          setYear={setYear}
          scrollY={scrollY}
        />

        <View style={webStyle.wrapper}>
          <DateTab
            activeTab={tab}
            setTab={setTab}
            month={month}
            quarter={quarter}
            year={year}
          />
          {calculatedData.map((x) => (
            <CompanyCard company={x} />
          ))}
          <div style={{height: "200px"}}></div>
        </View>
      </View>
    </div>
  );
};

const webStyle = {
  wrapper: {
    overflow: "scroll",
    height: "99vh",
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 20,
    paddingBottom: 20 + 30,
  },
  flexRow: {
    width: width - 40,
    marginHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    backgroundColor: colors.black,
    alignItems: "center",
  },
};

const styles = StyleSheet.create({
  contentContainerStyle: {
    alignItems: "center",
    height: "72vh",
  },
  text: {
    fontSize: 24,
    marginTop: 4,
    fontWeight: 500,
    fontFamily: "Poppins",
    textAlign: "center",
  },
  pressable: {
    width: 100,
    height: 40,
    backgroundColor: colors.lightGrey,
  },
});

export default Companies;
