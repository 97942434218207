import { Select, Space, Spin, message } from "antd";
import React, { useEffect } from "react";
import { LOGOUT } from "../../redux/modules/Users/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getApiEndpoint } from "../../axios/endpoints";
import moment from "moment";
import InfoCard from "./InfoCard";
import InfoCardMaterial from "./InfoCardMaterial";
import { TIME_PERIODS } from ".";

const MaterialReports = ({ assets, materials }) => {
  const {active_params} = useSelector((state) => state.Reports);

  const [filters, set_filters] = React.useState({
    shift: [],
    date: moment().format(active_params.year + "-" + active_params.month + "-" + 1),
  });
  const [reports, set_reports] = React.useState([]);
  const [loading, set_loading] = React.useState(false);


  const dispatch = useDispatch();



  useEffect(() => {
    getMaterialReports({
      shift: filters.shift,
      date: active_params.year + "-" + active_params.month + "-" + 1,
    });
  }, [active_params, filters.shift]);

  const getMaterialReports = async (queryParams) => {
    set_filters(queryParams)

    set_loading(true);
    const token = await localStorage.getItem("token");
    try {
      let res = await axios.get(
        `${getApiEndpoint()}waste/analytics/by_material/`,
        {
          params: {
            shift: queryParams.shift.join("|"),
            start: moment(queryParams.date).startOf(TIME_PERIODS.find(x => x.name === active_params.active_tab).standard).toISOString(),
            end: moment(queryParams.date).endOf(TIME_PERIODS.find(x => x.name === active_params.active_tab).standard).toISOString(),
          },
          headers: {
            Authorization: "Token " + token,
          },
        }
      );
      set_reports(res.data);
    } catch (error) {
      console.log(error, "error") 
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      message.error("Dogodila se greska kod dohvata...");
    } finally {
      set_loading(false);
    }
  };



  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "0 20px",
          marginBottom: 20,
          width: "100%",
        }}
      >
        <Select
          maxTagCount={1}
          mode="multiple"
          allowClear
          value={filters.shift}
          onSelect={(value) => {
            let tmp = [...filters.shift];
            tmp.push(value);
            getMaterialReports({ ...filters, shift: tmp });
          }}
          onDeselect={(value) => {
            console.log(value, "value")
            let tmp = [...filters.shift];
            console.log(tmp, "tmp")
            tmp = tmp?.filter((x) => x !== value) || [];
            getMaterialReports({ ...filters, shift: tmp });
          }}
          onClear={() => getMaterialReports({ ...filters, shift: [] })}
          placeholder="Smjena"
          style={{ width: "100%" }}
        >
          <Select.Option value="1">Prva smjena</Select.Option>
          <Select.Option value="2">Druga smjena</Select.Option>
          <Select.Option value="3">Treća smjena</Select.Option>
        </Select>
     
         
      </div>
      <div>
        {loading ? <div style={{padding: "12%", display: "flex", justifyContent: "center"}} ><Spin size="large" /></div> : reports.map((materialItem, index) => (
          <InfoCardMaterial key={index} materialItem={materialItem} />
        ))}
      </div>
    </>
  );
};

export default MaterialReports;

 