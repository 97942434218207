export const hasPermission = (permissions, requiredPerrmissions) => {
    let has = false;
    if(permissions?.filter(x => requiredPerrmissions.includes(x.name)).length > 0){
        has = true;
    }
    return has;
}

export const routeToRedirect = (permissions) => {
    
    if(!permissions){
        return "";
    }
    if(permissions.filter(x => x.name === "page_ttkabeli").length > 0){
        return ""
    }else if(permissions.filter(x => x.name === "page_kompanije").length > 0){
        return "/companies"
    }else if(permissions.filter(x => x.name === "page_livestrojevi").length > 0){
        return "/livemachines"
    }else if(permissions.filter(x => x.name === "waste_actual").length > 0){
        return "/wastepage";
    }else{
        return "/notfound"
    }
}