import React from "react";

// Ui
export default function HitSlop({
  children,
  onClick,
  correction = 40,
  parentWidth = 30,
  parentHeight = 24,
  width = 80,
  height = 80,
}) {
  return (
    <div
      style={{
        width: parentWidth,
        height: parentHeight,
        position: "relative",
        top: -27,
      }}
    >
        
      <div
        onClick={onClick}
        style={{
          width,
          height,
          transform: `translateY(calc(-50% + ${correction}px))`,
          position: "absolute",
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
}
