import { Select, Space, Spin, message } from "antd";
import React, { useEffect } from "react";
import { LOGOUT } from "../../redux/modules/Users/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getApiEndpoint } from "../../axios/endpoints";
import moment from "moment";
import InfoCard from "./InfoCard";
import { TIME_PERIODS } from ".";

const AssetReports = ({ assets, materials }) => {
  const [filters, set_filters] = React.useState({
    asset: [],
    shift: [],
    material: [],
    date: moment().format("yyyy-MM-DD"),
  });
  const [reports, set_reports] = React.useState([]);
  const [loading, set_loading] = React.useState(false);

  const {active_params} = useSelector((state) => state.Reports);

  const dispatch = useDispatch();

  useEffect(() => {
    getAssetReprots(filters);
  }, [active_params]);


  console.log(active_params)


  const getAssetReprots = async (queryParams) => {
    set_filters(queryParams)

    set_loading(true);
    const token = await localStorage.getItem("token");
    try {
      let res = await axios.get(
        `${getApiEndpoint()}waste/analytics/by_asset/`,
        {
          params: {
            asset: queryParams.asset.join("|"),
            shift: queryParams.shift.join("|"),
            material: queryParams.material.join("|"),
            start: moment(active_params.year + "-" + active_params.month + "-" + 1).startOf(TIME_PERIODS.find(x => x.name === active_params.active_tab).standard).toISOString(),
            end: moment(active_params.year + "-" + active_params.month + "-" + 1).endOf(TIME_PERIODS.find(x => x.name === active_params.active_tab).standard).toISOString(),
          },
          headers: {
            Authorization: "Token " + token,
          },
        }
      );
      set_reports(res.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      message.error("Dogodila se greska kod dohvata...");
    } finally {
      set_loading(false);
    }
  };



  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 12,
          padding: "0 20px",
          marginBottom: 20,
          width: "100%",
        }}
      >
        <Select
          maxTagCount={1}
          mode="multiple"
          allowClear
          value={filters.shift}
          onSelect={(value) => {
            let tmp = [...filters.shift];
            tmp.push(value);
            getAssetReprots({ ...filters, shift: tmp });
          }}
          onDeselect={(value) => {
            console.log(value, "value")
            let tmp = [...filters.shift];
            console.log(tmp, "tmp")
            tmp = tmp?.filter((x) => x !== value) || [];
            getAssetReprots({ ...filters, shift: tmp });
          }}
          onClear={() => getAssetReprots({ ...filters, shift: [] })}
          placeholder="Smjena"
          style={{ width: "100%" }}
        >
          <Select.Option value="1">Prva smjena</Select.Option>
          <Select.Option value="2">Druga smjena</Select.Option>
          <Select.Option value="3">Treća smjena</Select.Option>
        </Select>
        <Select
        allowClear
        value={filters.asset}
          maxTagCount={1}
          style={{ width: "100%" }}
          placeholder="Odaberi stroj"
          showSearch
          mode="multiple"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onClear={() => getAssetReprots({ ...filters, asset: [] })}
          onDeselect={(id) => {
            let tmp = [...filters.asset];
            tmp = tmp?.filter((x) => x !== id) || [];
            getAssetReprots({ ...filters, asset: tmp });
          }
          }
          onSelect={(id) => {
                let tmp = [...filters.asset];
                tmp.push(id);
                getAssetReprots({...filters, asset: tmp})
            }}
        >
          {assets.map((item, index) => {
            return (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            );
          })}
        </Select>
        <Select
        allowClear
        value={filters.material}
        mode="multiple"
        onClear={() => getAssetReprots({ ...filters, material: [] })}
        maxTagCount={1}
          placeholder="Odaberi materijal"
          style={{ width: "100%" }}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(id) => {
            let tmp = [...filters.material];
            tmp.push(id);
            getAssetReprots({...filters, material: tmp})
          }}
            onDeselect={(id) => {
                let tmp = [...filters.material];
                tmp = tmp?.filter((x) => x !== id) || [];
                getAssetReprots({...filters, material: tmp})
            }}

        >
          {materials.map((item, index) => {
            return (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div>
        {loading ? <div style={{padding: "12%", display: "flex", justifyContent: "center"}} ><Spin size="large" /></div> : reports.map((report, index) => (
          <InfoCard key={index} report={report} />
        ))}
      </div>
    </>
  );
};

export default AssetReports;
