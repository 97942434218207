export const LOGOUT = "LOGOUT";
export const LOGOUT_PROCEED = "LOGOUT_PROCEED";


export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const USER = "USER";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";

export const SET_TOKEN_FROM_LOCALE_STORAGE = "SET_TOKEN_FROM_LOCALE_STORAGE";
export const SET_TOKEN_FROM_LOCALE_STORAGE_PROCEED =
  "SET_TOKEN_FROM_LOCALE_STORAGE_PROCEED";

export const DESTROY_TOKEN_FOR_REDIRECT = "DESTROY_TOKEN_FOR_REDIRECT";

export const VERSION = "VERSION";
export const VERSION_SUCCESS = "VERSION_SUCCESS";
export const VERSION_FAIL = "VERSION_FAIL";

export const SET_MQTT = "SET_MQTT";




