import React, { useEffect, useRef, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  Pressable,
  Dimensions,
  ScrollView,
} from "react-native";

// Styles
import { colors } from "../styles/colors";
import styles from './style.module.css'
import { motion } from "framer-motion"


// Dates
import { Dates, getQuarter, getYears } from "../helpers/Dates";
import { useDispatch, useSelector } from "react-redux";
import { GET_REPORTS, SET_ACTIVE_TAB } from "../redux/modules/Reports/actions";

// Constants
let { width, height } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}
const TAB_WIDTH = 80;

export default function DateSlider({ setMonth, setQuarter, setYear }) {

  // References
  const scrollViewRef = useRef();

  // Variables
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const { reports, active_params } = useSelector((state) => state.Reports);



  //Styles

  const active = 
    active_params.active_tab === 'months' ?
      Dates.months[active_params.month - 1].date :
    active_params.active_tab === 'quarters' ? 
      Dates.quarters[active_params.quarter - 1].date.slice(-1) :
      Dates.years.find(item => item.value === active_params.year).date


  const activeIndicatorPositon = {
    // Months
    Siječanj: { left: 0 * TAB_WIDTH },
    Veljača: { left: 1 * TAB_WIDTH },
    Ožujak: { left: 2 * TAB_WIDTH },
    Travanj: { left: 3 * TAB_WIDTH },
    Svibanj: { left: 4 * TAB_WIDTH },
    Lipanj: { left: 5 * TAB_WIDTH },
    Srpanj: { left: 6 * TAB_WIDTH },
    Kolovoz: { left: 7 * TAB_WIDTH },
    Rujan: { left: 8 * TAB_WIDTH },
    Listopad: { left: 9 * TAB_WIDTH },
    Studeni: { left: 10 * TAB_WIDTH },
    Prosinac: { left: 11 * TAB_WIDTH },

    // Quarters
    1: { left: 0 * TAB_WIDTH },
    2: { left: 1 * TAB_WIDTH },
    3: { left: 2 * TAB_WIDTH },
    4: { left: 3 * TAB_WIDTH },

    // Years
    ...getYears(TAB_WIDTH).animationYears 
  }

  // Methods

  // Setting scrollview position to match current tab
  useEffect(() => {
    animateShadow();
  }, [active_params]);

  // Setting initial data on tab change
  useEffect(() => {
    setInitial();
  }, [active_params.active_tab]);

  // Setting initial data on component load
  useEffect(() => {
    setInitial();

    // Filling active indicator position object
    Dates.months.map((item, index) => 
      activeIndicatorPositon[item.date] = { 
        left: index * TAB_WIDTH 
      }
    )
  }, []);

 

  function setInitial() {
    let tmp = { ...active_params };
    switch (active_params.active_tab) {
      case "months":
        setActiveMonth(tmp.month);
        break;
      case "quarters":
        setActiveQuarter(tmp.quarter);
        break;
      case "years":
        setActiveYear(tmp.year);
        break;
    }
  }

  // Return right method based on tab
  function setActive(payload) {
    let tmp = { ...active_params };
    switch (active_params.active_tab) {
      case "months":
        tmp.month = payload;
        dispatch({ type: SET_ACTIVE_TAB, data: tmp });
        return setActiveMonth(payload);

      case "quarters":
        tmp.quarter = payload;
        dispatch({ type: SET_ACTIVE_TAB, data: tmp });

        return setActiveQuarter(payload);
      case "years":
        tmp.year = payload;
        dispatch({ type: SET_ACTIVE_TAB, data: tmp });
        return setActiveYear(payload);
    }
  }

  // Setting active month
  function setActiveMonth(month) {
    setMonth(month);
    Dates.months.map((item) => (item.isActive = false));
    Dates.months.find((item) => item.value === month).isActive = true;
    animateShadow();
  }

  // Setting active quarter
  function setActiveQuarter(quarter) {
    setQuarter(quarter);
    Dates.quarters.map((item) => (item.isActive = false));
    Dates.quarters.find((item) => item.value === quarter).isActive = true;
    animateShadow();
  }

  // Setting active year
  function setActiveYear(year) {
    // if (reports.year !== year) { dok nema mqtt-a ovako
    dispatch({ type: GET_REPORTS, queryParams: { year } });
    // }

    setYear(year);
    Dates.years.map((item) => (item.isActive = false));
    Dates.years.find((item) => item.value == year).isActive = true;
    animateShadow();
  }

  const animateShadow = () => {
    let nekiIndex = Dates[active_params.active_tab].findIndex(
      (item) => item.isActive === true
    );
    setIndex(nekiIndex);
    scrollViewRef.current.scrollLeft = nekiIndex * TAB_WIDTH - TAB_WIDTH;
  };

  return (
    <div id="dateSlider" className={styles.sliderContainer}>
      <div
        ref={scrollViewRef}
        className={styles.contentContainerStyle}
        style={{ width }}
      >
        {/* Animated indicator */}
        <motion.div 
          className={styles.sliderActiveIndicator}
          style={{ width: TAB_WIDTH }}
          variants={activeIndicatorPositon}
          animate={active}
        />

        {
          // Date slider
          Dates[active_params.active_tab].map((item) => (
            <div
              onClick={() => setActive(item.value)}
              key={item.date}
              className={styles.sliderTabView}
              style={{ width: TAB_WIDTH }}
            >
              <p 
                className={styles.sliderTabText} 
                style={{ 
                  opacity:  item.isActive ? 1 : .5, 
                  width: TAB_WIDTH,
                  fontWeight: item.isActive ? 500 : 300,
                  fontFamily: 'Poppins'
                }}
              >
                {item.date}
              </p>
            </div>
          ))
        }
      </div>
    </div>
  );
}