import React, { useEffect } from 'react'
import { Dimensions } from "react-native";
import { useLocation, NavLink } from "react-router-dom";

// Icons
import Proizvodnja from '../assets/icons/proizvodnja.png'
import Održavanja from '../assets/icons/prodaja.png'
import Prodaja from '../assets/icons/održavanja.png'
import Otpad from '../assets/icons/waste.png'
  
// Styles
import styles from './style.module.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { message } from 'antd';
  
// Constants
let width = Dimensions.get("screen").width;
if (width > 600) {
    width = 600;
}

export default function TabBar({ tab, tabs }){


    const { user } = useSelector((state) => state.Users);
    const history = useHistory();
    // Variables
    const route = useLocation().pathname
    const icons = {
        Proizvodnja,
        Prodaja,
        Održavanja,
        Otpad
    }

    useEffect(() => {
        if(user.account.page_permissions.find(x => x.name === "proizvodnja")){
            history.push("/");
        }else if(user.account.page_permissions.find(x => x.name === "prodaja")){
            history.push("/sales");
        }
        else if(user.account.page_permissions.find(x => x.name === "odrzavanje")){
            history.push("/maintenances");
        }
        else if(user.account.page_permissions.find(x => x.name === "waste")){
            history.push("/wastereport");
        }

            
    }, [])




    return(
        <div className={styles.tabBarContainer}>
            <div
                className={styles.tabBar}
                style={{ width: width - 40 }}
            >
                {
                    tab.tabs.map((item, index) => 
                        <div 
                        onClick={() => {
                            if(user.account.page_permissions?.filter(y => y.name === tabs[index].permission).length === 0){
                            message.warning("Nemate pravo pristupa ovoj stranici");
                            return;
                          }
                            history.push(tabs[index].route);
                        }}
                            key={index} 
                            className={styles.tabBarRoute}
                            style={{
                                padding: 6,
                                cursor: "pointer",
                                backgroundColor: route === item.route ? "#F0F0F0" : "#FFF"
                            }}
                        >
                            <img 
                                src={icons[item.title]} 
                                alt="Tab" 
                                className={styles.tabBarIcon} 
                                style={{ marginRight: route === item.route && 10 }}
                            />
                            { 
                                route === item.route && 
                                    item.title 
                            }
                        </div>    
                    )
                }
            </div>
        </div>
    )
}