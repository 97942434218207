import React from "react";

// Constants

import LOGO from "../../../assets/icons/logo.png";
import { Dimensions } from "react-native";
let { width, height } = Dimensions.get("screen");

if (width > 600) {
  width = 600;
}

export default function Header() {
  return (
    <>
      <div style={styles.headerWrapper}>
        {/* Logo */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={styles.logo} src={LOGO} />
          {/* <View style={[styles.logo, { backgroundColor: "#000", borderRadius: 8 }]} /> */}
          {/* App name */}
          <div style={styles.text}>Izvješće</div>
        </div>
      </div>
    </>
  );
}

const styles = {
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    width,
    marginTop: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 52,
    height: 52,
  },
  text: {
    fontSize: 24,
    marginTop: 4,
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
};
