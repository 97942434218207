import React, { Component } from "react";
import { Text, View, StyleSheet } from "react-native";
import moment from "moment";
import formatSeconds from "../../../../helpers/formatSeconds";

class TimeTicking extends Component {
  state = {
    currentCount: "00:00",
    intervalId: null,
  };

  componentDidMount() {
    this.timer();
    const intervalId = setInterval(this.timer, 1000);
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount() {
    if (this.props.tickingType === "meeting") {
      this.props.destroyLastOpendMeeting();
    }
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  timer = () => {
    // setState method is used to update the state
    let time = formatSeconds(
      moment
        .duration(moment(new Date()).diff(this.props.realStart))
        .asMilliseconds() / 1000
    );
    this.setState({
      currentCount: time,
    });
  };

  render() {
    // You do not need to decrease the value here
    return (
      <View style={styles.container}>
        <Text style={styles.label}>Trajanje: </Text>
        <Text style={styles.value}>{this.state.currentCount} h</Text>
      </View>
    );
  }
}

const styles = {
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    marginBottom: 40,
  },
  label: {
    color: "#000",
    opacity: 0.6,
    fontWeight: 400,
    fontFamily: 'Poppins',
    marginRight: 8,
  },
  value: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 14,
  },
};

export default TimeTicking;
