import { takeLatest, put, call, select } from "redux-saga/effects";

import axios_auth from "../../../axios/axios-auth";
import axios_user from "../../../axios/axios-user";

import * as actions from "./actions";
import {
  GET_REPORTS,
  SET_ERROR_REQUEST_NOT_VISIBLE,
  SET_MAINTENANCES_SUCCESS,
} from "../Reports/actions";
import store from "../../store";
import { getMqttClient } from "../../../helpers/mqtt";

const storageUser = async () => {
  let tmp = await localStorage.getItem("user");
  return JSON.parse(tmp);
};
const removeStorageItem = (item) => localStorage.removeItem(item);

const initialState = {
  user: {
    data: {},
    token: "",
    firebase_token: "",
    expireTime: 3600 * 3,
    permission: false,
  },

  login: {
    status: "",
  },

  getUser: {
    status: "loading",
  },

  mobile_version: null,
  mqtt: {
    client: null,
    loading: false,
  },
};

export default function reducer(state = initialState, action = {}) {
  var tmp;
  switch (action.type) {
    case actions.VERSION: {
      return {
        ...state,
        mobile_version: null,
      };
    }
    case actions.VERSION_SUCCESS: {
      return {
        ...state,
        mobile_version: action.data.mobile_version,
      };
    }
    case actions.VERSION_FAIL: {
      return {
        ...state,
        mobile_version: null,
      };
    }

    case actions.LOGOUT:
      return {
        ...state,
      };

    case actions.LOGOUT_PROCEED:
      return {
        ...state,
        user: {
          data: {},
          token: "",
          firebase_token: "",
          expireTime: 3600 * 3,
          permission: false,
        },
      };
    case actions.LOGIN:
      return {
        ...state,
        login: {
          status: "loading",
        },
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.data,
        auth: true,
        login: {
          status: "",
        },
      };
    case actions.LOGIN_FAIL:
      return {
        ...state,
        user: {},
        auth: false,
        login: {
          status:
            action.error && action.error.status === 400
              ? "not_valid_data"
              : "error",
        },
      };
    case actions.USER:
      return {
        ...state,
        getUser: {
          status: "loading",
        },
      };
    case actions.USER_SUCCESS:
      return {
        ...state,
        user: action.data,
        getUser: {
          status: "",
        },
      };

    case actions.USER_FAIL:
      return {
        ...state,
        getUser: {
          status: "error",
        },
      };
    case actions.SET_TOKEN_FROM_LOCALE_STORAGE_PROCEED:
      return {
        ...state,
        user: {
          data: {},
          token: action.token.token,
          loading: false,
          error: null,
          status: 200,
        },
      };

    case actions.DESTROY_TOKEN_FOR_REDIRECT:
      return {
        ...state,
        user: {},
      };

    case actions.SET_MQTT:
      return {
        ...state,
        mqtt: {
          client: action.data,
          loading: action.loading,
        },
      };

    default:
      return { ...state };
  }
}

function transformData(data) {
  return data.data;
}

const authToken = () => localStorage.getItem("token"); //kada se ubaci login

//set token from locale storage
function* setTokenFromLocaleStorage(token) {
  yield put({ type: actions.SET_TOKEN_FROM_LOCALE_STORAGE_PROCEED, token });
  // yield put({ type: actions.USER });
}

export function* watcherSetTokenFromLocaleStorage() {
  yield takeLatest(
    actions.SET_TOKEN_FROM_LOCALE_STORAGE,
    setTokenFromLocaleStorage
  );
}
//set token from locale storage end

//logout

export function* watcherLogout() {
  yield takeLatest(actions.LOGOUT, logout);
}

function _logout(options) {
  return axios_user(options).post("logout/");
}

function* logout() {
  yield localStorage.setItem("user", JSON.stringify({}));
  yield removeStorageItem("authTime");
  try {
    const user = yield call(storageUser);
    const options = {
      token: user.token,
    };
    yield put({ type: SET_ERROR_REQUEST_NOT_VISIBLE });
    yield put({ type: actions.LOGOUT_PROCEED });
  } catch (error) {
    yield put({ type: actions.LOGOUT_PROCEED });
  }
}

// login
function postData(options) {
  return axios_auth(options).post("auth/", options.data);
}

export function* watcherLogin() {
  yield takeLatest(actions.LOGIN, login);
}

function* login(payload) {
  try {
    if (!store.getState().Users.mqtt.client) {
      put({ type: actions.SET_MQTT, data: null, loading: true });
    }

    const options = {};
    options.data = payload.data;
    const response = yield call(postData, options);

    const data = transformData(response);

    yield localStorage.setItem("user", JSON.stringify(data));
    yield localStorage.setItem("authTime", new Date().toString());
    yield localStorage.setItem("token", data.token);
    yield put({ type: actions.LOGIN_SUCCESS, data });

    yield put({
      type: GET_REPORTS,
      queryParams: { year: new Date().getFullYear(), status: "iz_login_usera" },
    });

    if (!store.getState().Users.mqtt.client) {
      const mqtt = getMqttClient(["izvjesce/mms"], updateState);
      put({ type: actions.SET_MQTT, data: mqtt, loading: false });
    }

    // registerSw();
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: actions.LOGIN_FAIL, error: error.response });
  }
}
// End login

// get user by token
function fetchUser(options) {
  return axios_user(options).get(`getuser/?token=${options.token}`);
}

export function* watcherGetUserByToken() {
  yield takeLatest(actions.USER, getUser);
}

function* getUser() {
  //payload ukoliko bude potrebe za dohvatanje satanaka na osnovu nekog filtera

  try {
    if (!store.getState().Users.mqtt.client) {
      put({ type: actions.SET_MQTT, data: null, loading: true });
    }

    const token = yield call(authToken);
    const options = {
      token,
    };

    const response = yield call(fetchUser, options);

    let data = { ...transformData(response), ...{ token: options.token } };

    yield put({ type: actions.USER_SUCCESS, data });
    yield put({
      type: GET_REPORTS,
      queryParams: {
        year: store.getState().Reports.active_params.year,
        status: "iz_get_usera",
      },
    });

    if (!store.getState().Users.mqtt.client) {
      const mqtt = getMqttClient(["izvjesce/mms"], updateState);
      put({ type: actions.SET_MQTT, data: mqtt, loading: false });
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: actions.USER_FAIL, error });
  }
}
// End get user by token

const updateState = (data) => {
  store.dispatch({ type: SET_MAINTENANCES_SUCCESS, data: JSON.parse(data) });
};
