import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  ActivityIndicator,
  StatusBar,
} from "react-native";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import InBowl from "./components/Tabs/InBowl";
import Appointed from "./components/Tabs/Appointed";
import InProgress from "./components/Tabs/InProgress";
import Finished from "./components/Tabs/Finished";

import Header from "../../components/Header";
import { colors } from "../../styles/colors";
import "antd-mobile/lib/tabs/style/css";

import { Tabs } from "antd-mobile";

let { width, height } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}

function Dashboard({ toggleDrawer, set_toggleDrawer }) {
  // const scrollViewRef = useRef();

  const [trikyLoader, setTrikyLoader] = useState(true);
  const history = useHistory();

  let { data, status } = useSelector((state) => state.Reports.maintenances);
  let { mqtt } = useSelector((state) => state.Users);

  useEffect(() => {
    setTimeout(() => setTrikyLoader(false), 600);
  }, []);

  data = data.sort(function (a, b) {
    return b.type.id - a.type.id;
  });



  if (trikyLoader || mqtt.loading) {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          height,
          width,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ActivityIndicator color={colors.darkGrey} size={"large"} />
      </View>
    );
  }


  const badgeCounts = [
    {
      red:
        data.filter((item) => item.status === "in_bowl" && item.type.id === 2)
          .length || null,
      orange:
        data.filter((item) => item.status === "in_bowl" && item.type.id === 1)
          .length || null,
      grey:
        data.filter((item) => item.status === "in_bowl" && item.type.id === 3)
          .length || null,
    },
    {
      red:
        data.filter((item) => item.status === "appointed" && item.type.id === 2)
          .length || null,
      orange:
        data.filter((item) => item.status === "appointed" && item.type.id === 1)
          .length || null,
      grey:
        data.filter((item) => item.status === "appointed" && item.type.id === 3)
          .length || null,
    },
    {
      red: 0,
      orange: 0,
      green:
        data.filter(
          (item) => item.status === "in_progress" || item.status === "waiting"
        ).length || null,
    },

    {
      red:
        data.filter((item) => item.status === "finished" && item.type.id === 2)
          .length || null,
      orange:
        data.filter((item) => item.status === "finished" && item.type.id === 1)
          .length || null,
      grey:
        data.filter(
          (item) =>
            item.status === "finished" &&
            item.type.id !== 1 &&
            item.type.id !== 2
        ).length || null,
    },
  ];

  const tabs2 = [
    { title: "Nedodjeljeni", index: 0, current: "in_bowl" },
    { title: "Dodjeljeni", index: 1, current: "appointed" },
    { title: "U tijeku", index: 2, current: "in_progress" },
    { title: "Za potvrdu", index: 3, current: "finished" },
  ];

  const label = (route, color) => {
    return (
      <View style={styles.labelView}>
        <View style={styles.badgeView}>
          {route.current === "in_progress" ? (
            <View>
              {badgeCounts[route.index].green ? (
                <Text style={styles.greenBadge}>
                  {badgeCounts[route.index].green}
                </Text>
              ) : (
                <View style={styles.blankBadge}></View>
              )}
            </View>
          ) : (
            <View style={styles.flexRow}>
              {badgeCounts[route.index].grey ? (
                <Text style={styles.greyBadge}>
                  {badgeCounts[route.index].grey}
                </Text>
              ) : (
                <View style={styles.blankBadge}></View>
              )}
              {badgeCounts[route.index].red ? (
                <Text style={styles.redBadge}>
                  {badgeCounts[route.index].red}
                </Text>
              ) : (
                <View style={styles.blankBadge}></View>
              )}
              {badgeCounts[route.index].orange ? (
                <Text style={styles.orangeBadge}>
                  {badgeCounts[route.index].orange}
                </Text>
              ) : (
                <View style={styles.blankBadge}></View>
              )}
            </View>
          )}
        </View>
        <Text style={[styles.tabLabel, { color: color }]}>{route.title}</Text>
      </View>
    );
  };

  return (
    <>
      <StatusBar backgroundColor={colors.black} />
      <Header
        toggleDrawer={toggleDrawer}
        set_toggleDrawer={set_toggleDrawer}
        name="Održavanja"
      />
      <Tabs
        tabs={tabs2}
        tabBarPosition={"top"}
        renderTab={(tab) => {
          return label(tab, "");
        }}
        tabBarUnderlineStyle={{
          color: colors.darkGrey,
          backgroundColor: colors.darkGrey,
          borderColor: colors.darkGrey,
        }}
        initialPage={1}
      >
        <div>
          <InBowl />
        </div>
        <div>
          <Appointed />
        </div>
        <div>
          <InProgress />
        </div>
        <div>
          <Finished />
        </div>
      </Tabs>
    </>
  );
}

const styles = {
  errorBannerRelative: {
    padding: 6,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: "#000",
    textAlign: "center",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#fc1c0d",
    backgroundColor: "#fc9992",
    zIndex: 1234,
  },
  button: {
    borderWidth: 1,
    borderColor: "#4675ff",
    borderRadius: 4,
    width: 92,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 60,
  },
  buttonText: {
    color: "#4675ff",
    fontWeight: 500,
    fontFamily: 'Poppins',
    marginTop: 3,
  },
  container: {
    flexDirection: "column",
    height: height,
    width: width,
  },
  flexRow: {
    flexDirection: "row",
  },
  blankBadge: {
    width: 16,
    height: 16,
    borderWidth: 1,
    borderColor: "#00000020",
    borderStyle: "dashed",
    borderRadius: 500,
    marginRight: 4,
    paddingTop: 1,
  },
  tabBarStyle: {
    backgroundColor: "#FFF",
    color: "#000000",
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  labelView: {
    flexDirection: "column",
  },
  badgeView: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 4,
    height: 16,
  },
  redBadge: {
    width: 16,
    height: 16,
    borderWidth: 1,
    borderColor: "#ff184540",
    borderRadius: 500,
    textAlign: "center",
    color: "#ff1845",
    marginRight: 4,
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 10,
    paddingTop: 1,
  },
  orangeBadge: {
    width: 16,
    height: 16,
    borderWidth: 1,
    borderColor: "#fbb30080",
    borderRadius: 500,
    textAlign: "center",
    color: "#fbb300",
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 10,
    marginRight: 4,
    paddingTop: 1,
  },
  greyBadge: {
    width: 16,
    height: 16,
    borderWidth: 1,
    borderColor: "#55555580",
    borderRadius: 500,
    textAlign: "center",
    color: "#555",
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 10,
    marginRight: 4,
    paddingTop: 1,
  },
  greenBadge: {
    width: 16,
    height: 16,
    borderWidth: 1,
    borderColor: "#00d60e60",
    borderRadius: 500,
    textAlign: "center",
    color: "#00d60e",
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 10,
    paddingTop: 1,
  },
  tabLabel: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 10,
    textAlign: "center",
  },
  scrollViewHeight: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
  },
  technicianItemView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 4,
    paddingHorizontal: 4,
  },
  actionSheetContainer: {
    padding: 20,
    height: height * 0.75,
    maxHeight: height * 0.75,
  },
  actionSheetTitle: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 24,
    marginBottom: 16,
  },
  actionSheetDivider: {
    width: width - 40,
    height: 0.1,
    opacity: 0.3,
    borderStyle: "dashed",
    borderWidth: 0.6,
    borderRadius: 1,
    marginBottom: 20,
  },
  actionSheetConfirm: {
    width: width - 40,
    height: 60,
    marginTop: 32,
    backgroundColor: "#4675ff",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  actionSheetConfirmDisabled: {
    width: width - 40,
    height: 60,
    marginTop: 32,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#00000080",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  actionSheetConfirmText: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 16,
    color: "#FFF",
    marginTop: 4,
  },
  actionSheetConfirmTextDisabled: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 16,
    color: "#00000080",
    marginTop: 4,
  },
};

export default Dashboard;
