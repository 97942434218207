import { Form, Input, message, Button, Typography, Space, InputNumber, DatePicker, Switch, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Dimensions, StyleSheet, View } from "react-native-web";
import Header from "../../components/Header";
import { colors } from "../../styles/colors";
import axios from "axios";
import { getApiEndpoint } from "../../axios/endpoints";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../redux/modules/Users/actions";
import moment from "moment";

const { width } = Dimensions.get("screen");

const WastePlan = ({ toggleDrawer, set_toggleDrawer }) => {
  const [form] = Form.useForm();
  const [groupedMaterials, set_groupedMaterials] = useState();
  const [loading, set_loading] = useState(false);
  const dispatch = useDispatch();
  const [isMontly, set_isMontly] = useState(false);
  const [selectedDate, set_selectedDate] = useState(moment());

  const getWastePlanMaterials = async () => {
    let token = localStorage.getItem("token");
    try {
      let res = await axios.get(`${getApiEndpoint()}waste/waste_plan/materials/`, {
        headers: {
          Authorization: "Token " + token,
        },
      });

      console.log(res);
      set_groupedMaterials(res.data);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }

      message.error("Dogodila se greška.");
    }
  };

  const getWastePlan = async (queryParams) => {
    let token = localStorage.getItem("token");
    try {
      let res = await axios.get(`${getApiEndpoint()}waste/waste_plan/`, {
        params: queryParams,
        headers: {
          Authorization: "Token " + token,
        },
      });
      let month = res.data.month || new Date().getMonth() + 1;
      let dateString = res.data.year + "/" + month + "/1";
      form.setFieldsValue({
        date: moment(dateString),
      });
      res.data.materials.forEach((group) => {
        form.setFieldsValue({
          ["percentage|" + group.id + "|" + group.name]: group.percentage,
        });
        group.materials.forEach((material) => {
          form.setFieldsValue({
            ["ratio|" + material.id + "|" + material.name + "|" + group.id + "|" + group.name]: material.ratio,
          });
        });
      });
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }

      message.error("Dogodila se greška.");
    }
  };

  useEffect(() => {
    getWastePlan({
      year: selectedDate.year(),
      month: isMontly ? selectedDate.month() + 1 : null,
    });
  }, []);

  const onFinish = async (values) => {
    console.log(values);

    set_loading(true);
    let token = await localStorage.getItem("token");

    const output = {
      year: values.date.year(),
      month: isMontly ? values.date.month() + 1 : null,
      materials: [],
    };

    const fullMaterials = [];
    Object.keys(values).forEach((key) => {
      if (key.includes("percentage")) {
        const id = +key.split("|")[1];
        const name = key.split("|")[2];
        const percentage = values[key];
        const materials = [];
        Object.keys(values).forEach((key2) => {
          if (key2.includes("ratio")) {
            const id2 = key2.split("|")[1];
            const name2 = key2.split("|")[2];
            const id3 = +key2.split("|")[3];
            console.log(id, id3);
            if (id === id3) {
              materials.push({
                id: +id2,
                name: name2,
                ratio: values[key2],
              });
            }
          }
        });
        fullMaterials.push({
          full_material: {
            id: id,
            name: name,
          },
          percentage: percentage,
          materials: materials,
        });
      }
    });

    output.materials = fullMaterials;

    //here we save data to server

    try {
      let res = await axios.post(`${getApiEndpoint()}waste/waste_plan/`, output, {
        headers: {
          Authorization: "Token " + token,
        },
      });
      console.log(res);
      message.success("Uspješno spremljeno.");
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      message.error("Dogodila se greška.");
    } finally {
      set_loading(false);
    }

    console.log(output);
  };

  useEffect(() => {
    getWastePlanMaterials();
  }, []);

  useEffect(() => {
    getWastePlan({
      year: selectedDate.year(),
      month: isMontly ? selectedDate.month() + 1 : null,
    });
  }, [selectedDate, isMontly]);

  return (
    <>
      <View style={styles.container}>
        {/* Header */}
        <Header
          toggleDrawer={toggleDrawer}
          set_toggleDrawer={set_toggleDrawer}
          subname=""
          name="Plan otpada"
          canGoBack={true}
        />
      </View>
      <div style={{ padding: "0 6px", paddingTop: 20, height: "calc(100vh - 99px)", overflowY: "scroll" }}>
        <Form onFinish={onFinish} form={form}>
          <div>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Switch style={{ marginRight: 8 }} onChange={() => set_isMontly(!isMontly)} />
                <Typography.Text style={{ width: 72}} strong>
                  {isMontly ? "Mjesečni" : "Godišnji"}
                </Typography.Text>
                <Form.Item noStyle rules={[{ required: true, message: "Ovo polje je obavezno" }]} name={"date"}>
                  {isMontly ? (
                    <DatePicker
                      value={selectedDate}
                      onSelect={(value) => set_selectedDate(value)}
                      picker="month"
                    />
                  ) : (
                    <DatePicker
                      value={selectedDate}
                      onSelect={(value) => {
                        console.log(value, "KLICK");
                        set_selectedDate(value);
                      }}
                      picker="year"
                    />
                  )}
                </Form.Item>
              </div>
            </div>
          </div>

          <Divider style={{ marginTop: 20, marginBottom: 10 }} />

          <div style={{ width: "100%", position: "relative", display: "flex", marginBottom: 12 }}>
            <div style={{ width: "35%" }} />
            <Typography.Text style={{ width: "25%" }} strong>
              Otpad
            </Typography.Text>
            <Typography.Text style={{ width: "40%" }} strong>
              Omjer
            </Typography.Text>
          </div>

          {groupedMaterials?.map((group, index) => (
            <Form.Item key={index}>
              {/* Group */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography.Text style={{ minWidth: "35%", maxWidth: "35%" }}>{group.name}</Typography.Text>
                <div style={{ width: "60%", display: "flex", alignItems: "center" }}>
                  <Form.Item noStyle name={"percentage|" + group.id + "|" + group.name}>
                    <InputNumber style={{ width: "calc(100% - 8px)" }} />
                  </Form.Item>
                </div>
                {/* Materials */}
                <Input.Group
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    visibility: group.materials.length === 1 ? "hidden" : "visible",
                  }}
                >
                  <Space size={8}>
                    {group.materials.map((material, index) => (
                      <Form.Item
                        initialValue={group.materials.length === 1 ? 100 : undefined}
                        name={"ratio|" + material.id + "|" + material.name + "|" + group.id + "|" + group.name}
                        noStyle
                        rules={[{ required: true, message: "Sva polja su obavezna." }]}
                      >
                        <InputNumber placeholder={material.name} style={{ width: "100%" }} />
                      </Form.Item>
                    ))}
                  </Space>
                </Input.Group>
              </div>
            </Form.Item>
          ))}

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Spremi
            </Button>
          </Form.Item>
        </Form>
        <div style={{ height: 120 }} />
      </div>
    </>
  );
};

export default WastePlan;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingTop: 30,
    backgroundColor: colors.white,
    alignItems: "center",
  },

  contentContainerStyle: {
    alignItems: "center",
    height: "72vh",
  },
  text: {
    fontSize: 24,
    marginTop: 4,
    fontWeight: 500,
    fontFamily: "Poppins",
    textAlign: "center",
  },
  pressable: {
    width: 100,
    height: 40,
    backgroundColor: colors.lightGrey,
  },
  flexRow: {
    width: width - 40,
    marginHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
