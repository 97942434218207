import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import Information from "../../components/Information";

const MachineCard = ({ item }) => {

  const history = useHistory();
  return (
    <>
    <div
    onClick={() =>{
      history.push(`/livemachines/${item.device_id}`)}}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        
      }}
    >
        <div  style={{width: "50%", display: "flex", justifyContent: "flex-start", justifyItems: "center"}}>
          <div>
      <div
       style={{
        fontSize: 15,
        fontWeight: 500,
        fontFamily: "Poppins",
      }}
      >
        {item.device_name}
        <div style={{
          fontWeight: 400,
          fontFamily: "Poppins",
          fontSize: 12,
          color: "#424242"
        }} >
          {moment(item.time).format("LLL")}
        </div>
      </div>
      </div>
      </div>
      <div style={{width: "50%",    display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flexDirection: "row",}} >
      <div
        style={{
            fontWeight: 300,
            fontFamily: "Poppins",
            fontSize: 15,
          }}
       
      >
        {item.value}
      </div>
      <div>
        <span
          style={{
            display: "inline-block",
            borderRadius: "50%",
            width: 21,
            height: 21,
            backgroundColor: +item.value === 0 ? "#ff0000" : "#26ff00",
            margin: 6,
          }}
        ></span>
      </div>
      </div>
    </div>
    <div style={{margin: "6px 0px", height: 1, backgroundColor: "#00000030"}} />
    </>
  );
};

export default MachineCard;
