import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./main.css";

import { BrowserRouter as Router } from "react-router-dom";

import AppWrapper from "./AppWrapper";

ReactDOM.render(
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    }}
  >
    <div style={{ maxWidth: "600px", width: "100%", position: "relative" }}>
      <Provider store={store}>
        <Router>
          <AppWrapper />
        </Router>
      </Provider>
    </div>
  </div>,
  document.getElementById("root")
);
