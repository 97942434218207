import React from "react";
import { Dimensions, StyleSheet, View } from "react-native-web";
import Header from "../../components/Header";
import LoadingDisplay from "../../components/LoadingDisplay";
import { colors } from "../../styles/colors";
import WasteForm from "./WasteForm";
import { CloseCircleOutlined, HistoryOutlined } from "@ant-design/icons";
import { Button } from "antd";
import History from "./History/index";

const { width } = Dimensions.get("screen");

const Waste = ({ toggleDrawer, set_toggleDrawer }) => {
  const [loading, set_loading] = React.useState(false);
  const [selectedWaste, set_selectedWaste] = React.useState();
  const [historyVisible, set_historyVisible] = React.useState(false);
  const [historyList, set_historyList] = React.useState([]);

  return (
    <>
      <View style={styles.container}>
        {loading && <LoadingDisplay />}
        <Header
          name={historyVisible ? "Povijest unosa otpada" : "Unos otpada"}
          subname={undefined}
          canGoBack={true}
          toggleDrawer={toggleDrawer}
          set_toggleDrawer={set_toggleDrawer}
        />
      </View>

      <div style={{ padding: "0 6px", height: "calc(100vh - 99px)", overflowY: "scroll" }}>
        <div
          style={{
            textAlign: "center",
            padding: 3,
            position: "fixed",
            bottom: 36,
            right: 24,
          }}
        >
          <Button onClick={() => set_historyVisible(!historyVisible)} type="link">
            {historyVisible ? (
              <CloseCircleOutlined style={{ fontSize: 36, color: colors.darkGrey }} />
            ) : (
              <HistoryOutlined style={{ fontSize: 36, color: colors.darkGrey }} />
            )}{" "}
          </Button>
        </div>
        {historyVisible ? (
          <History set_historyList={set_historyList} historyList={historyList} />
        ) : (
          <WasteForm
            set_historyList={set_historyList}
            historyList={historyList}
            onClose={() => set_historyVisible(false)}
          />
        )}
      </div>
    </>
  );
};

export default Waste;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingTop: 30,
    backgroundColor: colors.white,
    alignItems: "center",
  },

  contentContainerStyle: {
    alignItems: "center",
    height: "72vh",
  },
  text: {
    fontSize: 24,
    marginTop: 4,
    fontWeight: 500,
    fontFamily: "Poppins",
    textAlign: "center",
  },
  pressable: {
    width: 100,
    height: 40,
    backgroundColor: colors.lightGrey,
  },
  flexRow: {
    width: width - 40,
    marginHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
