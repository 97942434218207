export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAIL = "GET_REPORTS_FAIL";

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

export const SET_ERROR_REQUEST_VISIBLE = "SET_ERROR_REQUEST_VISIBLE";
export const SET_ERROR_REQUEST_NOT_VISIBLE = "SET_ERROR_REQUEST_NOT_VISIBLE";

export const SET_P_PLAN = "SET_P_PLAN";
export const SET_P_PLAN_SUCCESS = "SET_P_PLAN_SUCCESS";
export const SET_P_PLAN_FAIL = "SET_P_PLAN_FAIL";

export const SET_S_PLAN = "SET_S_PLAN";
export const SET_S_PLAN_SUCCESS = "SET_S_PLAN_SUCCESS";
export const SET_S_PLAN_FAIL = "SET_S_PLAN_FAIL"; 

export const SET_MAINTENANCES = "SET_MAINTENANCES";
export const SET_MAINTENANCES_SUCCESS = "SET_MAINTENANCES_SUCCESS";
export const SET_MAINTENANCES_FAIL = "SET_MAINTENANCES_FAIL";

