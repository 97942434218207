import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, Pressable, Dimensions } from "react-native";
import { motion } from "framer-motion"

import { useDispatch, useSelector } from "react-redux";
import { SET_ACTIVE_TAB } from "../redux/modules/Reports/actions";

// Colors
import { colors } from "../styles/colors";

import styles from './style.module.css'

// Constants
let { width, height } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}
const TAB_WIDTH = (width - 46) / 3;
const TAB_HEIGHT = 38;

export default function DateTab({ activeTab, setTab, month, quarter, year }) {
  const dispatch = useDispatch();
  const { active_params } = useSelector((state) => state.Reports);
  // Variables
  const [currentTab, setCurrentTab] = useState(0);

  // Methods
  useEffect(() => {
    switch (active_params.active_tab) {
      case "months":
        setCurrentTab(0);
        break;
      case "quarters":
        setCurrentTab(1);
        break;
      case "years":
        setCurrentTab(2);
        break;
    }
  }, [active_params.active_tab]);

  function setActiveTab(tab) {
    setTab(tab);

    console.log(tab, "ACTIVE_TAB");

    let tmp = { ...active_params };

    tmp.active_tab = tab;
    dispatch({ type: SET_ACTIVE_TAB, data: tmp });
  }

  function parseTabName() {
    switch (active_params.active_tab) {
      case "months":
        return `Mjesečni (${active_params.month}.)`;
      case "quarters":
        return `Kvartalni (Q${active_params.quarter})`;
      case "years":
        return `Godišnji (${active_params.year}.)`;
    }
  }

  // Styles
  const activeIndicatorPositon = {
    months: {
      left: 0 * TAB_WIDTH
    },
    quarters: {
      left: 1 * TAB_WIDTH
    },
    years: {
      left: 2 * TAB_WIDTH
    },
  }

  return (
    <div className={styles.container} style={{ width: `calc(${width} - 40px)` }}>
      {/* Active indicator */}
      <motion.div 
        className={styles.activeIndicator}
        style={{ width: TAB_WIDTH }}
        animate={active_params.active_tab}
        variants={activeIndicatorPositon}
      >
        <div 
          className={styles.activeIndicatorView} 
          style={{ width: TAB_WIDTH, height: TAB_HEIGHT }}
        >
          <p className={styles.activeIndicatorText}>{parseTabName()}</p>
        </div>
      </motion.div>

      {/* Mjesečni */}
      <div 
        onClick={() => setActiveTab("months")} 
        className={styles.tab} 
        style={{ width: TAB_WIDTH }}
      >
        <p className={styles.tabText}>
          Mjesečni {`(${active_params.month}.)`}
        </p>
      </div>

      {/* Kvartalni */}
      <div 
        onClick={() => setActiveTab("quarters")} 
        className={styles.tab} 
        style={{ width: TAB_WIDTH }}
      >
        <p className={styles.tabText}>
          Kvartalni {`(Q${active_params.quarter})`}
        </p>
      </div>

      {/* Godišnji */}
      <div 
        onClick={() => setActiveTab("years")} 
        className={styles.tab} 
        style={{ width: TAB_WIDTH }}
      >
        <p className={styles.tabText}>
          Godišnji {`(${active_params.year}.)`}
        </p>
      </div>
    </div>
  );
}