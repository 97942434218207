import { Button, DatePicker, Form, InputNumber, Select, message } from "antd";
import React, { useEffect } from "react";
import { getApiEndpoint } from "../../axios/endpoints";
import { LOGOUT } from "../../redux/modules/Users/actions";
import { useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";

const WasteForm = ({ waste, onClose, set_historyList, historyList }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [loading, set_loading] = React.useState(false);
  const [materials, set_materials] = React.useState([]);
  const [saveLoading, set_saveLoading] = React.useState(false);
  const [assets, set_assets] = React.useState([]);
  const [parsedData, set_parsedData] = React.useState([]);
  const [materialRequired, set_materialRequired] = React.useState(true);

  useEffect(() => {
    getAssets();
    if (waste?.id) {
      getMaterials(waste.asset.id);
    }
  }, []);

  const onFinish = async (values) => {
    let tmpMaterials = [];
    console.log(values);

    materials.forEach((x, i) => {
      tmpMaterials.push({
        full_material: {
          id: x.id,
          name: x.name,
        },
        qty: values.materials[i],
        materials: materials[i].materials,
      });
    });

    let body = {
      asset: values.asset,
      shift: +values.shift,
      date: values.date.format("yyyy-MM-DD"),
      materials: tmpMaterials,
    };

    set_saveLoading(true);
    let token = localStorage.getItem("token");
    try {
      let method = waste ? "put" : "post";
      let url = waste ? `${getApiEndpoint()}waste/waste/${waste.id}/` : `${getApiEndpoint()}waste/waste/`;
      let res = await axios[method](url, body, {
        headers: {
          Authorization: "Token " + token,
        },
      });
      let tmpList = [...historyList];
      if (method === "post") {
        tmpList.push(res.data);
        set_historyList(tmpList);
      } else {
        let index = tmpList.findIndex((x) => x.id === waste.id);
        tmpList[index] = res.data;
        set_historyList(tmpList);
      }
      set_saveLoading(false);
      message.success("Uspješno spremljeno!");
      form.resetFields();
      set_materialRequired(true);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      set_loading(false);
      message.error(error.response?.data?.message || "Dogodila se greska kod spremanja...");
    } finally {
      set_saveLoading(false);
      onClose();
    }
  };

  const getMaterials = async (asset) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let res = await axios.get(`${getApiEndpoint()}waste/waste/asset_materials/?asset=${asset}`, {
        headers: {
          Authorization: "Token " + token,
        },
      });
      set_materials(Array.isArray(res.data) ? res.data : []);
      set_loading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }

      message.error("Dogodila se greska kod dohvata...");
      set_loading(false);
    }
  };

  const getAssets = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let res = await axios.get(`${getApiEndpoint()}waste/asset/`, {
        headers: {
          Authorization: "Token " + token,
        },
      });
      set_assets(Array.isArray(res.data) ? res.data : []);
      set_loading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }

      message.error("Dogodila se greska kod dohvata...");
      set_loading(false);
    }
  };

  return (
    <div>
      <Form
        onChange={() => {
          if (form.getFieldsValue().materials && form.getFieldsValue().materials.filter((x) => x).length > 0) {
            set_materialRequired(false);
          } else {
            set_materialRequired(true);
          }
        }}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          date: waste ? moment(waste.created_at) : moment(),
          shift: waste ? waste.shift.toString() : "1",
          asset: waste ? waste.asset.id : undefined,
          materials: waste ? waste.materials.map((x) => x.qty) : undefined,
        }}
      >
        <Form.Item label="Datum" name="date">
          <DatePicker format="DD.MM.YYYY" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Ovo polje je obavezno.",
            },
          ]}
          label="Smjena"
          name="shift"
        >
          <Select>
            <Select.Option value="1">Prva smjena</Select.Option>
            <Select.Option value="2">Druga smjena</Select.Option>
            <Select.Option value="3">Treća smjena</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Ovo polje je obavezno.",
            },
          ]}
          label="Imovina"
          name="asset"
        >
          <Select
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onSelect={(id) => getMaterials(id)}
          >
            {assets.map((item, index) => {
              return (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        {materials.map((material, index) => (
          <Form.Item
            style={{ maxWidth: 120, width: "100%", display: "inline-block" }}
            key={material.id}
            label={`${material.name}`}
            name={["materials", index]}
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: index === 0 ? materialRequired : false,
                message: "Ovo polje je obavezno!",
              },
            ]}
          >
            <InputNumber onChange={() => {}} inputMode="numeric" placeholder="Masa" />
          </Form.Item>
        ))}

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Spremi
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WasteForm;
