// Dates
const getYears = (TAB_WIDTH) => {
  let d = new Date();
  let index = 0
  let animationYears = {}

  let arr = [];
  for (let year = 2021; year <= d.getFullYear() + 1; year++) {
    arr.push({
      date: year.toString(),
      value: year,
      isActive: false,
    });

    animationYears[year] = { left: index++ * TAB_WIDTH }

  }

  return { arr, animationYears };
};

const Dates = {
  months: [
    {
      date: "Siječanj",
      value: 1,
      isActive: false,
    },
    {
      date: "Veljača",
      value: 2,
      isActive: false,
    },
    {
      date: "Ožujak",
      value: 3,
      isActive: false,
    },
    {
      date: "Travanj",
      value: 4,
      isActive: false,
    },
    {
      date: "Svibanj",
      value: 5,
      isActive: false,
    },
    {
      date: "Lipanj",
      value: 6,
      isActive: false,
    },
    {
      date: "Srpanj",
      value: 7,
      isActive: false,
    },
    {
      date: "Kolovoz",
      value: 8,
      isActive: false,
    },
    {
      date: "Rujan",
      value: 9,
      isActive: false,
    },
    {
      date: "Listopad",
      value: 10,
      isActive: false,
    },
    {
      date: "Studeni",
      value: 11,
      isActive: false,
    },
    {
      date: "Prosinac",
      value: 12,
      isActive: false,
    },
  ],
  quarters: [
    {
      date: "Kvartal 1",
      value: 1,
      isActive: false,
    },
    {
      date: "Kvartal 2",
      value: 2,
      isActive: false,
    },
    {
      date: "Kvartal 3",
      value: 3,
      isActive: false,
    },
    {
      date: "Kvartal 4",
      value: 4,
      isActive: false,
    },
  ],
  years: getYears().arr,
  //   years: [
  //     // TODO
  //     {
  //       date: "2018",
  //       value: 2018,
  //       isActive: false,
  //     },
  //     {
  //       date: "2019",
  //       value: 2019,
  //       isActive: false,
  //     },
  //     {
  //       date: "2020",
  //       value: 2020,
  //       isActive: false,
  //     },
  //     {
  //       date: "2021",
  //       value: 2021,
  //       isActive: false,
  //     },
  //     {
  //       date: "2022",
  //       value: 2022,
  //       isActive: false,
  //     },
  //     {
  //       date: "2023",
  //       value: 2023,
  //       isActive: false,
  //     },
  //     {
  //       date: "2024",
  //       value: 2024,
  //       isActive: false,
  //     },
  //   ], // TODO
};

const getQuarter = () => {
  let today = new Date();
  let quarter = Math.floor((today.getMonth() + 3) / 3);
  return quarter;
};

export { Dates, getQuarter, getYears };
