import { Typography } from "antd";
import React from "react";
import Information from "../../components/Information";

const InfoCard = ({report}) => {


    return (
        <div>
            <Typography.Title level={5} style={{fontSize: 18, fontWeight: "bold"}}>{report.asset.name}</Typography.Title>
            <Information  type={"waste"} _width={"100%"} value={report.planned_waste} label={"Planirani"}  />
            <Information  type={"waste"} _width={"100%"} value={report.unplanned_waste} label={"Neplanirani"}  />
            <div style={{paddingLeft: 24}} >
                {report.unplanned_by_material.map((item, index) => <Information  type={"waste"} _width={"100%"} key={index} value={item.qty} label={item.full_material.name}  />)}
            </div>
        </div>
    )
}

export default InfoCard;