import React, { useEffect } from "react";
import LoadingDisplay from "../../../components/LoadingDisplay";
import { LOGOUT } from "../../../redux/modules/Users/actions";
import { useDispatch } from "react-redux";
import { getApiEndpoint } from "../../../axios/endpoints";
import axios from "axios";
import Card from "./Card";
import { Modal, Select, DatePicker, message } from "antd";
import WasteForm from "../WasteForm";
import moment from "moment";
import { unstable_batchedUpdates } from "react-dom";

const { RangePicker, WeekPicker, MonthPicker, YearPicker } = DatePicker;

const History = ({ historyList, set_historyList }) => {
  const [loading, set_loading] = React.useState(false);
  const dispatch = useDispatch();
  const [historyToUpdate, set_historyToUpdate] = React.useState();
  const [assets, set_assets] = React.useState([]);
  const [filters, set_filters] = React.useState({
    start: moment().startOf("week").toISOString(),
    end: moment().endOf("week").toISOString(),
    shift: [],
    asset: [],
  });
  const [pickerType, set_pickerType] = React.useState("range");

  const getAssets = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let res = await axios.get(`${getApiEndpoint()}waste/asset/`, {
        headers: {
          Authorization: "Token " + token,
        },
      });
      set_assets(Array.isArray(res.data) ? res.data : []);
      set_loading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }

      message.error("Dogodila se greska kod dohvata...");
      set_loading(false);
    }
  };

  const getHistory = async (filters) => {
    set_filters(filters);

    set_loading(true);
    let token = localStorage.getItem("token");
    try {
      let res = await axios.get(`${getApiEndpoint()}waste/waste/`, {
        params: {
          asset: filters?.asset.join("|"),
          shift: filters?.shift.join("|"),
          start: moment(filters?.start).toISOString(),
          end: moment(filters?.end).toISOString(),
        },
        headers: {
          Authorization: "Token " + token,
        },
      });
      set_historyList(res.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      message.error("Dogodila se greska kod dohvata...");
    } finally {
      set_loading(false);
    }
  };

  useEffect(() => {
    getHistory(filters);
    getAssets();
  }, []);

  const generatePicker = () => {
    if (pickerType === "week") {
      return (
        <WeekPicker
          size="large"
          style={{ width: "100%" }}
          value={moment(filters.start)}
          key={filters.start}
          allowClear={false}
          format="DD.MM.YYYY"
          inputReadOnly={true}
          onSelect={(val) => {
            getHistory({
              ...filters,
              start: moment(val).startOf("week").toISOString(),
              end: moment(val).endOf("week").toISOString(),
            });
          }}
        />
      );
    } else if (pickerType === "month") {
      return (
        <MonthPicker
          size="large"
          style={{ width: "100%" }}
          value={moment(filters.start)}
          key={filters.start}
          allowClear={false}
          format="DD.MM.YYYY"
          inputReadOnly={true}
          onSelect={(val) => {
            getHistory({
              ...filters,
              start: moment(val).startOf("month").toISOString(),
              end: moment(val).endOf("month").toISOString(),
            });
          }}
        />
      );
    } else if (pickerType === "year") {
      return (
        <YearPicker
          size="large"
          style={{ width: "100%" }}
          value={moment(filters.start)}
          key={filters.start}
          allowClear={false}
          format="DD.MM.YYYY"
          inputReadOnly={true}
          onSelect={(val) => {
            getHistory({
              ...filters,
              start: moment(val).startOf("year").toISOString(),
              end: moment(val).endOf("year").toISOString(),
            });
          }}
        />
      );
    } else if (pickerType === "range") {
      return (
        <RangePicker
          size="large"
          style={{ width: "100%" }}
          value={[moment(filters.start), moment(filters.end)]}
          defaultPickerValue={[moment(filters.start), moment(filters.end)]}
          key={filters.start}
          allowClear={false}
          inputReadOnly={true}
          format="DD.MM.YYYY"
          onChange={(date, stringDate) => {
            if (!date || date[1].toISOString() === filters.end) {
              return;
            }
            getHistory({
              ...filters,
              start: date[0].toISOString(),
              end: date[1].toISOString(),
            });
          }}
        />
      );
    }
  };

  return (
    <>
      {loading && <LoadingDisplay />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "6px 0",
        }}
      >
        {generatePicker()}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Select
          mode="multiple"
          onDeselect={(val) => {
            let values = filters.shift.filter((item) => item !== val);
            unstable_batchedUpdates(() => {
              getHistory({
                ...filters,
                shift: values,
              });
            }, []);
          }}
          onClear={() => {
            getHistory({
              ...filters,
              shift: [],
            });
          }}
          value={filters.shift}
          size="large"
          style={{ width: "100%" }}
          placeholder="Odaberi smjenu"
          onSelect={(val) => {
            unstable_batchedUpdates(() => {
              let values = [...filters.shift, val];

              getHistory({
                ...filters,
                shift: values,
              });
            }, []);
          }}
        >
          <Select.Option value={"1"}>Prva smjena</Select.Option>
          <Select.Option value={"2"}>Druga smjena</Select.Option>
          <Select.Option value={"3"}>Treća smjena</Select.Option>
        </Select>
        <Select
          size="large"
          mode="multiple"
          style={{ width: "100%" }}
          showSearch
          placeholder="Odaberi stroj"
          value={filters.asset}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onClear={() => {
            getHistory({
              ...filters,
              asset: [],
            });
          }}
          onDeselect={(id) => {
            let values = filters.asset.filter((item) => item !== id);
            unstable_batchedUpdates(() => {
              getHistory({
                ...filters,
                asset: values,
              });
            }, []);
          }}
          onSelect={(id) => {
            unstable_batchedUpdates(() => {
              let values = [...filters.asset, id];
              getHistory({
                ...filters,
                asset: values,
              });
            }, []);
          }}
        >
          {assets.map((item, index) => {
            return (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div>
        {historyList.map((item, index) => (
          <Card key={item.id} index={index} onClick={set_historyToUpdate} item={item} />
        ))}
      </div>
      <Modal
        visible={!!historyToUpdate}
        onCancel={() => set_historyToUpdate(undefined)}
        footer={null}
        width={400}
        centered
        destroyOnClose
      >
        <WasteForm
          waste={historyToUpdate}
          onClose={() => set_historyToUpdate(undefined)}
          set_historyList={set_historyList}
          historyList={historyList}
        />
      </Modal>
    </>
  );
};

export default History;
