export const getTechnicianStatus = (order, user) => {
  // currentUser
  const technician = order.technicians.find(
    (tec) => tec.account.id === user.id
  );
  if (technician === -1 || technician === undefined) {
    return false;
  }

  if (!technician.flows.slice(-1)[0]) {
    return "";
  }
  return technician.flows.slice(-1)[0].status;
};

export const isTechnicianPrimary = (order, user) => {
  // currentUser
  const technician = order.technicians.find(
    (tec) => tec.account.id === user.id
  );
  if (technician === -1 || technician === undefined) {
    return false;
  }

  return technician.is_primary;
};

export const isTechnicianBusy = (order, user) => {
  // currentUser
  let technician;
  try {
    technician = order.technicians.find((tec) => tec.account.id === user.id);
  } catch (error) {
    console.log(error);
  }
  if (technician === -1 || technician === undefined) {
    return false;
  }

  return technician.account.status;
};

export const technicianOfOrder = (order, user) => {
  if (!order) {
    return false;
  }

  const technician = order.technicians.find(
    (tec) => tec.account.id === user.id
  );
  if (technician === -1 || technician === undefined) {
    return null;
  }

  return technician;
};

export const canUserTakeOrder = (order, data, currentUser, flag) => {
  if (flag === "continue") {
    return true;
  }
  let userOrders = data.filter((order) => order.status !== "in_bowl");

  try {
    if (
      userOrders.length > 0 &&
      technicianOfOrder(userOrders[0], currentUser).account.status === "busy"
    ) {
      return false;
    }
  } catch (error) {
    console.log(error, "GFGFG");
  }

  if (order.type.id === 2) {
    return true;
  }
  if (
    order.type.id === 1 &&
    data.filter(
      (item) =>
        item.type.id === 2 &&
        (item.status === "in_bowl" || item.status === "appointed")
    ).length > 0
  ) {
    return false;
  }

  return true;
};

export const isTechnicianWaiting = (order, currentUser) => {
  const technician = technicianOfOrder(order, currentUser);
  if (!technician) {
    return false;
  }

  if (technician.flows.length) {
    return false;
  }

  let check = technician.flows.slice(-1)[0];

  if (check.end) {
    return true;
  }

  return false;
};

export const showCommandsToReporter = (technicians, currentUserId) => {
  // only if is order in appointed or in_progress used to clear reporter commands
  let check = technicians.find((item) => item.account.id === currentUserId);
  if (check === -1 || check === undefined) {
    return false;
  }
  return true;
};
