import React, { useRef, useEffect, useState } from "react";

// import MqttService from "./mqtt/MqttService"

// Components
import TabBar from "./components/TabBar";

import { Switch, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import Sider from "./navigation/Sider";
import Production from "./containers/Production";
import FormProduction from "./containers/Production/form";
import SalesForm from "./containers/Sales/Form";
import Maintenances from "./containers/Maintenances";
import { Tabs } from "antd-mobile";
import { useSelector, useDispatch } from "react-redux";
import { Dimensions } from "react-native";
import { USER } from "./redux/modules/Users/actions";
import Auth from "./containers/Auth";
import "antd-mobile/lib/tabs/style/css";
import Sales from "./containers/Sales";
import LoadingDisplay from "./components/LoadingDisplay";
import { colors } from "./styles/colors";
import store from "./redux/store";
import { GET_REPORTS } from "./redux/modules/Reports/actions";
import Companies from "./containers/Companies";
import LiveStrojevi from "./containers/LiveStrojevi";
import { hasPermission, routeToRedirect } from "./helpers/functions";
import axios from "axios";
import { getApiEndpoint } from "./axios/endpoints";
import Machine from "./containers/Machine";
import Waste from "./containers/Waste";
import WasteReport from "./containers/WasteReport";
import WastePlan from "./containers/WasteReport/WastePlan";
import NotFound from "./containers/NotFound";
import { message } from "antd";
let { width } = Dimensions.get("screen");

let visitorFlag = true;

if (width > 600) {
  width = 600;
}

const AppWrapper = () => {
  const { getUser, user } = useSelector((state) => state.Users);

  const [toggleDrawer, set_toggleDrawer] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();


  const visitorCounter = async () => {
    let token = await localStorage.getItem("token");
    axios.post(`${getApiEndpoint()}reports/visitorcounter/`, null, {
      headers: {
        Authorization: "Token " + token
      }
    })
  }

  useEffect(() => {
    dispatch({ type: USER });
    visitorCounter();

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'visible') {
        store.dispatch({
          type: GET_REPORTS,
          queryParams: { year: store.getState().Reports.active_params.year },
        });
  
        visitorCounter();
      }
    
    });


    return () => {
      document.removeEventListener("visibilitychange", null);
    };
  }, []);

  if(  getUser.status === "loading"){
    return  <LoadingDisplay />;
  }

  if (!user.token) {
    return <Auth />;
  }


  const tabs2 = [
    { title: "Proizvodnja", sub: 0, route: "/", permission: "proizvodnja" },
    { title: "Prodaja", sub: 1, route: "/sales", permission: "prodaja" },
    { title: "Održavanja", sub: 2, route: "/maintenances", permission: "odrzavanje" },
    { title: "Otpad", sub: 3, route: "/wastereport", permission: "waste" },
  ];



  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Sider toggleDrawer={toggleDrawer} set_toggleDrawer={set_toggleDrawer} />

      <Switch >
       {hasPermission(user.account?.page_permissions, ["page_ttkabeli"]) && <Route
          path="/sales"
          render={() => (
            <Sales
              toggleDrawer={toggleDrawer}
              set_toggleDrawer={set_toggleDrawer}
            />
          )}
        />}
       {hasPermission(user.account?.page_permissions, ["page_ttkabeli"]) &&  <Route
          path="/salesform"
          render={() => (
            <SalesForm
              toggleDrawer={toggleDrawer}
              set_toggleDrawer={set_toggleDrawer}
            />
          )}
        />}
       { hasPermission(user.account?.page_permissions, ["page_ttkabeli"]) && <Route
          path="/formproduction"
          render={() => (
            <FormProduction
              toggleDrawer={toggleDrawer}
              set_toggleDrawer={set_toggleDrawer}
            />
          )}
        />}
         { hasPermission(user.account?.page_permissions, ["waste"]) && <Route
          path="/wastereport"
          render={() => (
            <WasteReport
              toggleDrawer={toggleDrawer}
              set_toggleDrawer={set_toggleDrawer}
            />
          )}
        />}
           { hasPermission(user.account?.page_permissions, ["waste_plan"]) && <Route
          path="/wasteplan"
          render={() => (
            <WastePlan
              toggleDrawer={toggleDrawer}
              set_toggleDrawer={set_toggleDrawer}
            />
          )}
        />}
       {hasPermission(user.account?.page_permissions, ["page_ttkabeli"]) &&  <Route
          path="/maintenances"
          render={() => (
            <Maintenances
              toggleDrawer={toggleDrawer}
              set_toggleDrawer={set_toggleDrawer}
            />
          )}
        />}
         
      {hasPermission(user.account?.page_permissions, ["page_kompanije"]) &&   <Route
          path="/companies"
          render={() => (
            <Companies
              toggleDrawer={toggleDrawer}
              set_toggleDrawer={set_toggleDrawer}
            />
          )}
        />} 
           {hasPermission(user.account?.page_permissions, ["page_livestrojevi"]) &&  <Route
           exact
          path="/livemachines"
          render={() => (
            <LiveStrojevi
            toggleDrawer={toggleDrawer}
            set_toggleDrawer={set_toggleDrawer}
            />
          )}
        />}

        {hasPermission(user.account?.page_permissions, ["page_livestrojevi"]) &&  <Route
        exact
          path="/livemachines/:id"
          render={() => (
            <Machine
            toggleDrawer={toggleDrawer}
            set_toggleDrawer={set_toggleDrawer}
            />
          )}
        />}
         {hasPermission(user.account?.page_permissions, ["waste_actual"]) &&  <Route
          path="/wastepage"
          render={() => (
            <Waste
              toggleDrawer={toggleDrawer}
              set_toggleDrawer={set_toggleDrawer}
            />
          )}
        />} 

       {hasPermission(user.account?.page_permissions, ["page_ttkabeli"]) &&  <Route
          path="/"
          render={() => (
            <Production
              toggleDrawer={toggleDrawer}
              set_toggleDrawer={set_toggleDrawer}
            />
          )}
        />} 

        <Route path="/notfound" render={() => <NotFound  toggleDrawer={toggleDrawer}
              set_toggleDrawer={set_toggleDrawer} />} />
      

        <Redirect to={routeToRedirect(user.account?.page_permissions)} />
      </Switch>
      
        {!location.pathname.includes("wastepage") && !location.pathname.includes("livemachines") && location.pathname !== "/companies" && 
          <div
          style={{ position: "absolute", top: "calc(100vh - 100px)", width }}
        >
          <Tabs
            tabs={tabs2}
            tabBarUnderlineStyle={{ display: "none" }}
            tabBarTextStyle={{
              color: colors.darkGrey,
            }}
            
            renderTabBar={(tab) => <TabBar tabs={tabs2} tab={tab} />}
            tabBarPosition={"top"}
            initialPage={getActiveRoute(tabs2, location.pathname)}
          />
        </div>
        }
        
    </div>
  );
};

const getActiveRoute = (tabs, pathname) => {
  let tmp = tabs.find((x) => x.route === pathname);
  if (tmp === -1 || tmp === undefined) {
    return null;
  }
  return tmp.sub;
};

export default AppWrapper;
