import { Typography } from "antd";
import moment from "moment";
import React from "react";

const Card = ({ item, onClick, index }) => {
  return (
    <div
      onClick={() => onClick(item)}
      style={{ padding: "6px 8px", marginInline: 4, borderRadius: 2, backgroundColor: index % 2 ? "#f1f1f1" : "transparent" }}
    >
      <div>
        <Typography.Text type="secondary">{moment(item.created_at).format("LLL")}</Typography.Text>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "calc(50% - 36px)" }}>
          <Typography.Text strong>{item.created_by.full_name}</Typography.Text>
        </div>
        <div style={{ width: 72 }}>
          <Typography.Text strong>{item.shift + ". "}Smjena</Typography.Text>
        </div>
        <div style={{ width: "calc(50% - 36px)", textAlign: "right" }}>
          <Typography.Text strong>{item.asset.name}</Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default Card;
