export const sortWorkingOrderToTop = (data) => {
    const tmp_arr_1 = [];
    const tmp_arr_2 = [];
  
    data.map((item) => {
      if (item.status === "in_progress") {
        tmp_arr_1.push(item);
      } else {
        tmp_arr_2.push(item);
      }
    });
  
    return [...tmp_arr_1, ...tmp_arr_2];
  };
  