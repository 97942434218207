import React from "react";
import { Text, Image, StyleSheet, View, Dimensions } from "react-native";

import { formatPrice } from "../helpers/priceFormater";
import INFORMATION_ICON from "../assets/icons/information.png";

let { height, width } = Dimensions.get("screen");

if (width > 600) {
  width = 600;
}

export default function Information({ label, value, calculation, type, calculation_2, currency, _width }) {



  

  let _type = undefined;   

  if(type === "sales"){
    _type = formatPrice(value, currency);
  }else if(type === "waste"){
    _type = (+value).toFixed(2) + " t";
  }else{
    _type =  (+value / 1000).toFixed(2) + " t"
  }

  return (
    <View
      style={{
      width: _width ? _width : width,
        marginBottom: 18,
        paddingHorizontal: 15,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img style={{ width: 39, height: 39 }} src={INFORMATION_ICON} />
        <Text style={[{ marginLeft: 9 }, styles.label]}>{label}</Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {label === "Duljina" ? (
          <Text style={[styles.value]}>{+value.toFixed(2) + " "}km</Text>
        ) : (
          <Text style={[styles.value]}>
            {_type}
          </Text>
        )}

        {calculation && (
          <Text
            style={{
              fontWeight: 500,
              fontFamily: 'Poppins',
              color: calculation.split("")[0] === "-" ? "#f44336" : "#2196f3",
            }}
          >
            {calculation}
          </Text>
        )}
        <div></div>
        {calculation_2 && (
          <Text
            style={{
              fontWeight: 500,
              fontFamily: 'Poppins',
              color: calculation_2.split("")[0] === "-" ? "#f44336" : "#2196f3",
            }}
          >
            {calculation_2}
          </Text>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  label: {
    fontWeight: 300,
    fontFamily: 'Poppins',
    fontSize: 15,
  },
  value: {
    fontSize: 15,
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
});
