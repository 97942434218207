import React, { useEffect, useState } from "react";
import { colors } from "../../styles/colors";
import { StyleSheet, View, StatusBar, Dimensions } from "react-native-web";
import DateSlider from "../../components/DateSlider";
import DateTab from "../../components/DateTab";
import LoadingDisplay from "../../components/LoadingDisplay";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { Select, Tabs, message } from "antd";
import axios from "axios";
import { getApiEndpoint } from "../../axios/endpoints";
import { LOGOUT } from "../../redux/modules/Users/actions";
import MaterialReports from "./MaterialReports";
import AssetReports from "./AssetReports";
import { FormOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { hasPermission } from "../../helpers/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { width } = Dimensions.get("screen");

export const TIME_PERIODS = [{
  standard: "quarter",
  name: "quarters"
},
{
  standard: "month",
  name: "months"
},
{
  standard: "year",
  name: "years"
}]

const { TabPane } = Tabs;

const WasteReport = ({ toggleDrawer, set_toggleDrawer }) => {
  const history = useHistory();
  const { active_params } = useSelector((state) => state.Reports);
  const { user } = useSelector((state) => state.Users);
  const [tab, setTab] = useState(active_params.active_tab);
  const [month, setMonth] = useState(active_params.month);
  const [quarter, setQuarter] = useState(active_params.quarter);
  const [year, setYear] = useState(active_params.year);
  const [groupedMaterials, set_groupedMaterials] = useState([]);
  const [assets, set_assets] = useState([]);
  const [loading, set_loading] = useState(false);
  const [materials, set_materials] = useState([]);
  const [filter, set_filters] = useState({
    shift: [],
    asset: [],
    material: [],
    });

    const [activeKey, set_activeKey] = useState("1");
  const dispatch = useDispatch();

  useEffect(() => {
    getAssets();
    // getMaterials();
    getWastePlanMaterials();
  }, []);


 

 

const getWastePlanMaterials = async () => {
  let token = localStorage.getItem("token");
  try {
    let res = await axios.get(`${getApiEndpoint()}waste/waste_plan/materials/`, {
      headers: {
          Authorization: 'Token ' + token,
      }}); 

        set_groupedMaterials(res.data);
  } catch (error) {
    console.log(error);
    if(error?.response?.status === 401){
        dispatch({type: LOGOUT});
        return;
    }

    message.error("Dogodila se greška.");
  }
}
  const getAssets = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let res = await axios.get(`${getApiEndpoint()}waste/asset/`, {
        headers: {
          Authorization: "Token " + token,
        },
      });
      set_assets(Array.isArray(res.data) ? res.data : []);
      set_loading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }

      // message.error("Dogodila se greska kod dohvata...");
      set_loading(false);
    }
  };

  const onChange = (key) => {
    set_activeKey(key)
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: `Materijali`,
      children: <MaterialReports shift={filter.shift} />,
    },
    {
      key: '2',
      label: `Strojevi`,
      children: <AssetReports assets={assets} materials={groupedMaterials} />,
    },
  ];

  const _loadingDisplay = loading === "loading" && <LoadingDisplay />;



  return (
    <>
      <StatusBar backgroundColor={colors.black} />
      <View style={styles.container}>
        {_loadingDisplay}
        {/* Header */}
        <Header
          toggleDrawer={toggleDrawer}
          set_toggleDrawer={set_toggleDrawer}
          name="Otpad"
        />
        {/* Date Slider */}

        <DateSlider
          tab={tab}
          setMonth={setMonth}
          setQuarter={setQuarter}
          setYear={setYear}
          scrollY={0}
        />

        {/* Wrapper */}
        <View style={webStyle.wrapper}>
          {/* Date Tab */}
          <DateTab
            activeTab={tab}
            setTab={setTab}
            month={month}
            quarter={quarter}
            year={year}
          />
        

        <div style={{padding: "0 6px", height: "calc(100vh - 330px)", overflowY: "scroll"}} >
      <Tabs centered tabBarExtraContent={
        hasPermission(user.account?.page_permissions, ["waste_plan"]) &&
        <Link to="../wasteplan" >
        <FormOutlined style={{fontSize: 24, color: colors.darkGrey, marginLeft: 12}} />
        </Link>
      }  defaultActiveKey="1" onChange={onChange}>
            {items.map((item) => (
              <TabPane tab={item.label} key={item.key}>
                {item.children}
              </TabPane>
            ))}
          </Tabs>
          </div>
      </View>

      </View>
      
      
    </>
  );
};

export default WasteReport;

const webStyle = {
  wrapper: {
    overflow: "scroll",
    height: "99vh",
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 20,
    paddingBottom: 20 + 30,
    width: "100%"
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingTop: 30,
    backgroundColor: colors.black,
    alignItems: "center",
  },
  wrapper: {
    width,
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: "auto",
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 20,
    paddingBottom: 20 + 30,
  },
  contentContainerStyle: {
    alignItems: "center",
    height: "72vh",
  },
  text: {
    fontSize: 24,
    marginTop: 4,
    fontWeight: 500,
    fontFamily: "Poppins",
    textAlign: "center",
  },
  pressable: {
    width: 100,
    height: 40,
    backgroundColor: colors.lightGrey,
  },
  flexRow: {
    width: width - 40,
    marginHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
