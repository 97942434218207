const INCIDENTNO = "#fcebee";
const LIGHT_GRAY = "#f1f1f1";
const WHITE = "#fff";

export const getBackgroundColorOfOrder = (item, index) => {
  if (false && item.type.id === 2) {
    return INCIDENTNO;
  } else if (index % 2 !== 0) {
    return LIGHT_GRAY;
  }

  return WHITE;
};
