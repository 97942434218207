import React, { useState, useRef, useEffect } from "react";
import {
  StatusBar,
  View,
  Text,
  TextInput,
  StyleSheet,
  Dimensions,
  Pressable,
  ActivityIndicator,
  Keyboard,
  Image,
} from "react-native";

// Components
import Header from "./components/Header";

// Styles
import { colors } from "../../styles/colors";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN } from "../../redux/modules/Users/actions";

import SHOW_ICON from "../../assets/icons/show.png";
import HIDE_ICON from "../../assets/icons/hide.png";

// Constants
let { width, height } = Dimensions.get("screen");

if (width > 600) {
  width = 600;
}

export default function Auth() {
  // Variables
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const disabled = email === "" || password === "";

  const { login } = useSelector((state) => state.Users);

  const dispatch = useDispatch();

  // References
  const passwordRef = useRef();
  const submitRef = useRef();

  // Constants
  const buttonStyle =
    email !== "" && password !== "" ? colors.black : colors.white;
  const buttonTextStyle =
    email !== "" && password !== "" ? colors.white : colors.darkGrey;
  const buttonBorderStyle =
    email !== "" && password !== "" ? colors.black : colors.darkGrey;
  const emailStyle = isEmailFocused ? colors.darkGrey : colors.lightGrey;
  const passwordStyle = isPasswordFocused ? colors.darkGrey : colors.lightGrey;

  // Methods
  useEffect(() => {
    // dispatch({ type: USER });
    getEmailAddress();
  }, []);

  function handleSubmit() {
    // Hide keyboard
    Keyboard.dismiss();

    // Save email
    saveEmailAddress(email);
    dispatch({
      type: LOGIN,
      data: {
        email,
        password,
      },
    });
  }

  function togglePasswordIndicator() {
    setPasswordIsVisible((previousState) => !previousState);
  }

  function saveEmailAddress(email) {
    localStorage.setItem("last-email", email);
  }

  async function getEmailAddress() {
    let tmp;
    try {
      tmp = await localStorage.getItem("last-email");
    } catch (e) {
      console.log(e);
    }
    tmp ? setEmail(tmp) : setEmail("");
  }

  return (
    <>
      <StatusBar backgroundColor="#FFFFFF" barStyle="dark-content" />
      <View style={styles.container}>
        {/* Header */}
        <Header />

        <Text style={[styles.errorMessage, { opacity: 0 === 400 ? 1 : 0 }]}>
          Došlo je do pogreške u prijavi
        </Text>

        {/* E-mail */}
        <View style={styles.wrapper}>
          <Text style={styles.label}>E-mail adresa</Text>
          <TextInput
            style={[
              styles.textInput,
              {
                borderColor: emailStyle,
                backgroundColor: email !== "" ? colors.white : colors.lightGrey,
              },
            ]}
            placeholder={"Unesite e-mail adresu..."}
            placeholderTextColor="#727272"
            onFocus={() => setIsEmailFocused(true)}
            onBlur={() => setIsEmailFocused(false)}
            autoCapitalize="none"
            keyboardType="email-address"
            value={email}
            onChangeText={(value) => setEmail(value)}
            onSubmitEditing={() => {
              passwordRef.current.focus();
            }}
            blurOnSubmit={false}
          />
        </View>

        {/* Lozinka */}
        <View style={styles.wrapper}>
          <Text style={styles.label}>Lozinka</Text>
          <TextInput
            style={[
              styles.textInput,
              {
                borderColor: passwordStyle,
                backgroundColor:
                  password !== "" ? colors.white : colors.lightGrey,
              },
            ]}
            ref={passwordRef}
            placeholder={"Unesite lozinku..."}
            placeholderTextColor="#727272"
            onFocus={() => setIsPasswordFocused(true)}
            onBlur={() => setIsPasswordFocused(false)}
            autoCapitalize="none"
            textContentType="password"
            secureTextEntry={!passwordIsVisible}
            value={password}
            onChangeText={(value) => setPassword(value)}
            onSubmitEditing={handleSubmit}
            blurOnSubmit={false}
          />

          {/* Password is visible button  */}
          <Pressable
            style={styles.passwordIndicatorView}
            onPress={togglePasswordIndicator}
          >
            {passwordIsVisible ? (
              <Image style={styles.passwordIndicator} source={SHOW_ICON} />
            ) : (
              <Image style={styles.passwordIndicator} source={HIDE_ICON} />
            )}
          </Pressable>
        </View>

        {/* Submit button */}
        <Pressable
          ref={submitRef}
          disabled={disabled}
          onPress={handleSubmit}
          style={({ pressed }) => [
            styles.submitButton,
            {
              backgroundColor: pressed ? colors.grey : buttonStyle,
              borderColor: buttonBorderStyle,
            },
          ]}
        >
          {login.status === "loading" ? (
            <ActivityIndicator size="small" color={colors.white} />
          ) : (
            <Text style={[styles.submitText, { color: buttonTextStyle }]}>
              Prijava
            </Text>
          )}
        </Pressable>
        <View style={{ height: 39 }}></View>
        {login.status === "error" ? (
          <Text
            style={[
              styles.sectionName,
              {
                color: "#ff1845",
              },
            ]}
          >
            Dogodila se greška, pokušajte ponovo!
          </Text>
        ) : null}
        {login.status === "not_valid_data" ? (
          <Text
            style={[
              styles.sectionName,
              {
                color: "#ffc107",
              },
            ]}
          >
            Izgleda da su podaci neispravni...
          </Text>
        ) : null}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  wrapper: {
    marginTop: 18,
  },
  textInput: {
    width: width - 40,
    borderWidth: 1,
    borderColor: colors.darkGrey,
    borderRadius: 8,
    backgroundColor: colors.darkGrey,
    color: colors.black,
    paddingHorizontal: 16,
    paddingVertical: 14,
    paddingTop: 16,
    marginTop: 8,
    fontWeight: 400,
    fontFamily: 'Poppins'
  },
  label: {
    marginLeft: 12,
    fontWeight: 400,
    fontFamily: 'Poppins'
  },
  submitButton: {
    width: 200,
    height: 52,
    marginTop: 32,
    backgroundColor: colors.orange,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 8,
  },
  submitText: {
    fontSize: 16,
    paddingTop: 4,
    fontWeight: 500,
    fontFamily: 'Poppins'
  },
  errorMessage: {
    color: "#F6738E",
    fontWeight: 500,
    textAlign: "center",
    fontSize: 12,
    marginTop: -12,
  },
  passwordIndicatorView: {
    position: "absolute",
    width: 54,
    height: 54,
    top: 41,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  passwordIndicator: {
    position: "relative",
    top: -12,
    width: 20,
    height: 20,
  },
});
