import React from "react";
import { ActivityIndicator, Dimensions, View, Text } from "react-native";
import { colors } from "../styles/colors";

const { width, height } = Dimensions.get("screen");

const LoadingDisplay = () => {
  return (
    <View
      style={{
        backgroundColor: "#f0f0f0e0",
        width: "100%",
        height,
        position: "absolute",
        elevation: 34234234,
        zIndex: 212312,
        top: 0,
        left: 0,
        opacity: .3
      }}
    >
      <ActivityIndicator style={{marginTop: height * .3}} size="large" color={colors.darkGrey} />
    </View>
  );
};

export default LoadingDisplay;
