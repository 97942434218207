import "intl";
import "intl/locale-data/jsonp/hr-BA";

var formatter = new Intl.NumberFormat("hr-BA", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatPrice = (value, currency = "BAM") => {
  return formatter.format(+value) + " " + currency;
};
