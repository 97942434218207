import React from "react";
import Information from "../../../components/Information";
import { View, Dimensions, Text } from "react-native-web";

// Constants
let { width, height } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}

const last_update = (ts) => {
      if (ts) {
        let d = ts.split("T")[0]
        let t = ts.split("T")[1].split(".")[0]
        let year = d.split("-")[0]
        let month = d.split("-")[1]
        let day = d.split("-")[2]
        let hours = t.split(":")[0]
        let minutes = t.split(":")[1]
        return day + "." + month + "." + year + ". " + hours + ":" + minutes
      }
     return " - "
    }

const CompanyCard = ({ company }) => {
  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width,
          paddingHorizontal: 21,
          paddingVertical: 12,
        }}
      >
        <Text style={{ fontSize: 21, fontWeight: 600, fontFamily: "Poppins" }}>
          {company.name}
        </Text>
      </View>

      
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width,
          paddingHorizontal: 21,
          paddingVertical: 12,
        }}
      >
        <Text style={{ fontSize: 15, fontFamily: "Poppins", color: 'rgba(0,0,0,0.5)' }}>
          Ažurirano: {last_update(company.report.timestamp)}
        </Text>
      </View>


      <View>
        <Information
          value={company.report.PV}
          label={"Prodajna vrijednost"}
          calculation={null}
          type={"sales"}
          currency="€"
        />
        <Information
          value={company.report.NV}
          label={"Nabavna vrijednost"}
          calculation={null}
          type={"sales"}
          currency="€"
        />
        <Information
          value={company.report.RUC}
          label={"Razlika u cijeni (RUC)"}
          calculation={((company.report.RUC / company.report.NV) * 100).toFixed(2) + "%"}
          type={"sales"}
          currency="€"
        />
      </View>
    </>
  );
};

export default CompanyCard;
