import React from "react";

import {
  Modal,
  Text,
  View,
  StyleSheet,
  Pressable,
  Dimensions,
} from "react-native";

import { useSelector, useDispatch } from "react-redux";
import { SET_ERROR_REQUEST_NOT_VISIBLE } from "../redux/modules/Reports/actions";

let { width, height } = Dimensions.get("screen");
if(width > 600){
  width = 600;
}

const ErrorRequestModal = () => {
  const { errorRequestVisible } = useSelector((state) => state.Reports);
  const dispatch = useDispatch();

  return (
    <Modal
      backdropColor="transparent"
      style={{
        alignItems: "center",
        justifyContent: "center",
      }}
      animationType="slide"
      transparent={true}
      visible={errorRequestVisible}
      onRequestClose={() => {
        dispatch({ type: SET_ERROR_REQUEST_NOT_VISIBLE });
      }}
    >
      <Pressable
        onPress={() => {
          dispatch({ type: SET_ERROR_REQUEST_NOT_VISIBLE });
        }}
      >
        <View style={{ width, height, backgroundColor: "#f0f0f0e0" }}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text
                style={[
                  styles.sectionName,
                  {
                    color: "#ff1845",
                    // fontWeight: 600
                    fontWeight: 600,
                    fontFamily: 'Poppins',
                  },
                ]}
              >
                Dogodila se greška! Pokušaj ponovo!
              </Text>
            </View>
          </View>
        </View>
      </Pressable>
    </Modal>
  );
};

export default ErrorRequestModal;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 15,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontFamily: 'Poppins',
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});
