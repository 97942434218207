import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  Text,
  StatusBar,
  ScrollView,
} from "react-native";

import * as calculate from "../../helpers/calculate";

// Components
import Header from "../../components/Header";
import DateSlider from "../../components/DateSlider";
import DateTab from "../../components/DateTab";
import Button from "./components/Button";
import ErrorRequestModal from "../../components/ErrorRequestModal";
import LoadingDisplay from "../../components/LoadingDisplay";

// Styles
import { colors } from "../../styles/colors";

import Information from "../../components/Information";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Constants
let { width, height } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}

export default function Production({ toggleDrawer, set_toggleDrawer }) {
  const {
    reports: {
      data: {
        sales: {
          reports,
          plan: { months },
        },
      },
      status,
    },
    active_params,
  } = useSelector((state) => state.Reports);
  // Variables
  const history = useHistory();
  const [tab, setTab] = useState(active_params.active_tab);
  const [month, setMonth] = useState(active_params.month);
  const [quarter, setQuarter] = useState(active_params.quarter);
  const [year, setYear] = useState(active_params.year);


  const render_data = calculate.sales(active_params, reports, months || {});

  const _loadingDisplay = status === "loading" && <LoadingDisplay />;

  const last_update = () => {
    if (active_params.active_tab == 'months') {
      if (reports[active_params.month].timestamp != null) {
        let ts = reports[active_params.month].timestamp
        let d = ts.split("T")[0]
        let t = ts.split("T")[1].split(".")[0]
        let year = d.split("-")[0]
        let month = d.split("-")[1]
        let day = d.split("-")[2]
        let hours = t.split(":")[0]
        let minutes = t.split(":")[1]
        return day + "." + month + "." + year + ". " + hours + ":" + minutes
      }
      return ' - '
    }
    return null
  };

  const show_last_update = () => {
    if (last_update() == null) {
      return
    }
    return (
      <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 15, fontFamily: "Poppins", color: 'rgba(0,0,0,0.5)' }}
            >
              Ažurirano: {last_update()}
            </Text>
          </View>
    )
  }


  return (
    <>
      <StatusBar backgroundColor={colors.black} />
      <View style={styles.container}>
        {_loadingDisplay}
        {/* Header */}
        <Header
          toggleDrawer={toggleDrawer}
          set_toggleDrawer={set_toggleDrawer}
          name="Pregled prodaje"
        />
        {/* Date Slider */}

        <DateSlider
          tab={tab}
          setMonth={setMonth}
          setQuarter={setQuarter}
          setYear={setYear}
          scrollY={0}
        />

        {/* Wrapper */}
        <View style={webStyle.wrapper}>
          {/* Date Tab */}
          <DateTab
            activeTab={tab}
            setTab={setTab}
            month={month}
            quarter={quarter}
            year={year}
          />

          <View
            style={{
              width,
              paddingHorizontal: 12,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Button
              label="GP Prodajna vrijednost"
              value={render_data.plan.sales_value}
              color={colors.orange}
              onPress={() => history.push("salesform")}
            />

            <Button
              label="GP Nabavna vrijednost"
              value={render_data.plan.cost_value}
              color={colors.orange}
              onPress={() => history.push("salesform")}
            />

            <Button
              label="GP RUC"
              value={(
                render_data.plan.sales_value - render_data.plan.cost_value
              ).toFixed(2)}
              calculation={calculate.calculateRucPrec(
                render_data.plan.sales_value,
                render_data.plan.cost_value
              )}
              color={colors.green}
              onPress={() => history.push("salesform")}
            />
          </View>

          {show_last_update()}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 21, fontWeight: 500, fontFamily: "Poppins" }}
            >
              Stanje
            </Text>
          </View>
          <View>
            <Information
              value={render_data.model_of_sum.PRODAJNA_VRIJEDNOST}
              calculation={null}
              type={"sales"}
              label={"Stvarna PV"}
            />
            <Information
              value={render_data.model_of_sum.PLANIRANA_NABAVNA_VR}
              calculation={null}
              type={"sales"}
              label={"Planirana NV"}
            />
            <Information
              value={render_data.model_of_sum.STVARNA_NABAVNA_VR}
              calculation={null}
              type={"sales"}
              label={"Stvarna NV"}
            />
            <Information
              value={render_data.model_of_sum.PLANIRANI_RUC}
              calculation={calculate.calcPlaniraniRuc(
                render_data.model_of_sum.PRODAJNA_VRIJEDNOST,
                render_data.model_of_sum.PLANIRANI_RUC
              )}
              calculation_2={calculate.calcPlaniraniRuc_2(
                render_data.plan.sales_value,
                render_data.model_of_sum.PRODAJNA_VRIJEDNOST
              )}
              type={"sales"}
              label={"Planirana RUC"}
            />
            <Information
              value={render_data.model_of_sum.STVARNI_RUC}
              calculation={calculate.calcPlaniraniRuc(
                render_data.model_of_sum.PRODAJNA_VRIJEDNOST,
                render_data.model_of_sum.STVARNI_RUC
              )}
              calculation_2={calculate.calcPlaniraniRuc_2(
                render_data.model_of_sum.PRODAJNA_VRIJEDNOST -
                  render_data.model_of_sum.STVARNI_RUC,
                render_data.model_of_sum.PRODAJNA_VRIJEDNOST
              )}
              type={"sales"}
              label={"Stvarna RUC"}
            />
            <Information
              value={
                render_data.model_of_sum.STVARNI_RUC -
                render_data.model_of_sum.PLANIRANI_RUC
              }
              calculation={null}
              type={"sales"}
              label={"Ključ"}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 21, fontWeight: 500, fontFamily: "Poppins" }}
            >
              Prodana količina
            </Text>
          </View>
          <View>
            <Information
              value={render_data.model_of_sum.PRODANI_KILOMETRI}
              calculation={null}
              label={"Duljina"}
            />
            <Information
              value={render_data.model_of_sum.PRODANE_TONE}
              calculation={null}
              label={"Masa"}
            />
            <Information
              value={render_data.model_of_sum.PRODANE_TONE_CU}
              calculation={null}
              label={"Bakar"}
            />
            <Information
              value={render_data.model_of_sum.PRODANE_TONE_AL}
              calculation={null}
              label={"Aluminij"}
            />
            <Information
              value={render_data.model_of_sum.PRODANE_TONE_PVC}
              calculation={null}
              label={"PVC"}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width,
              paddingHorizontal: 21,
              paddingVertical: 12,
            }}
          >
            <Text
              style={{ fontSize: 21, fontWeight: 500, fontFamily: "Poppins" }}
            >
              Stvarna PV po kategoriji
            </Text>
          </View>
          <View>
            <Information
              value={render_data.model_of_sum.PRODAJNA_VRIJEDNOST_EN}
              calculation={null}
              type={"sales"}
              label={"Energetika"}
            />
            <Information
              value={render_data.model_of_sum.PRODAJNA_VRIJEDNOST_IN}
              calculation={null}
              type={"sales"}
              label={"Instalacije"}
            />
          </View>

          {/* Padding bottom */}
          <View style={{ height: 200 }} />
        </View>

        <ErrorRequestModal />
      </View>
    </>
  );
}

const webStyle = {
  wrapper: {
    overflow: "scroll",
    height: "99vh",
    // width,
    // flexShrink: 1,
    // flexGrow: 1,
    // flexBasis: "auto",
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 20,
    paddingBottom: 20 + 30,
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingTop: 30,
    backgroundColor: colors.black,
    alignItems: "center",
  },
  wrapper: {
    width,
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: "auto",
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 20,
    paddingBottom: 20 + 30,
  },
  contentContainerStyle: {
    alignItems: "center",
    height: "72vh",
  },
  text: {
    fontSize: 24,
    marginTop: 4,
    fontWeight: 500,
    fontFamily: "Poppins",
    textAlign: "center",
  },
  pressable: {
    width: 100,
    height: 40,
    backgroundColor: colors.lightGrey,
  },
  flexRow: {
    width: width - 40,
    marginHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
