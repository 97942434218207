import React from "react";
import { View, Text, Pressable } from "react-native";
import { LOGOUT } from "../../redux/modules/Users/actions";
import { colors } from "../../styles/colors";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import IMG_LOGOUT from "../../assets/icons/logout.png";
import { hasPermission } from "../../helpers/functions";

const Sider = ({ toggleDrawer, set_toggleDrawer }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { user: {account: {page_permissions}} } = useSelector((state) => state.Users);

  let siderCssClasses = "sider ";
  if (toggleDrawer) {
    siderCssClasses += "open";
  }

  const getPageBasedOnPermission = () => {
    if(page_permissions.find(x => x.name === "proizvodnja")){
      return "/";
  }else if(page_permissions.find(x => x.name === "prodaja")){
      return "/sales";
  }
  else if(page_permissions.find(x => x.name === "odrzavanje")){
      return "/maintenances";
  }
  else if(page_permissions.find(x => x.name === "waste")){
      return "/wastereport";
  }
  }


  const pageTTKabeli =    getPageBasedOnPermission();


   



   


  return (
    <>
      <div className={siderCssClasses}>
        <div className={"siderContent"}>
          <Text
            style={{
              textAlign: "center",
              paddingBottom: 24,
              fontWeight: 400,
              fontFamily: "Poppins",
              display: "block",
              width: "100%",
              fontSize: 24,
            }}
          >
            Izvješće
          </Text>

         {hasPermission(page_permissions, ["page_ttkabeli"]) &&  <Link
            onClick={() => {
              set_toggleDrawer(false);
            }}
            to={pageTTKabeli}
            style={{
              fontSize: 15,
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#000",
              padding: 12,
              borderBottomColor: colors.lightGrey,
              borderBottomWidth: 1,
              backgroundColor:
                location.pathname === "/" && colors.orange,
              display: "block",
            }}
          >
            TT kabeli
          </Link>}

{ hasPermission(page_permissions, ["page_kompanije"]) &&         <Link
            onClick={() => {
              set_toggleDrawer(false);
            }}
            to="/companies"
            style={{
              marginTop: 21,
              fontSize: 15,
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#000",
              padding: 12,
              borderBottomColor: colors.lightGrey,
              borderBottomWidth: 1,
              backgroundColor:
                location.pathname === "/companies" && colors.orange,
              display: "block",
            }}
          >
            {" "}
            Kompanije
          </Link>}
         {hasPermission(page_permissions, ["page_livestrojevi"]) &&  <Link
            onClick={() => {
              set_toggleDrawer(false);
            }}
            to="/livemachines"
            style={{
              marginTop: 21,
              fontSize: 15,
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#000",
              padding: 12,
              borderBottomColor: colors.lightGrey,
              borderBottomWidth: 1,
              backgroundColor:
                location.pathname === "/livemachines" && colors.orange,
              display: "block",
            }}
          >
            {" "}
            Live strojevi
          </Link>}
          {hasPermission(page_permissions, ["waste_actual"]) &&  <Link
            onClick={() => {
              set_toggleDrawer(false);
            }}
            to="/wastepage"
            style={{
              marginTop: 21,
              fontSize: 15,
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#000",
              padding: 12,
              borderBottomColor: colors.lightGrey,
              borderBottomWidth: 1,
              backgroundColor:
                location.pathname === "/wastepage" && colors.orange,
              display: "block",
            }}
          >
            {" "}
            Otpad
          </Link>}

          <View style={{ position: "absolute", top: "91vh", left: 30 }}>
            <Pressable
              onPress={() => {
                dispatch({ type: LOGOUT });
                set_toggleDrawer(false);
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: 6,
                paddingHorizontal: 15,
                borderRadius: 18,
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  paddingTop: 3,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                }}
              >
                Odjava
              </Text>
              <img
                style={{ width: 21, height: 21, marginLeft: 12 }}
                src={IMG_LOGOUT}
              />
            </Pressable>
          </View>
        </div>
        <div
          onClick={() => set_toggleDrawer(false)}
          className={"siderClosable"}
        ></div>
      </div>
    </>
  );
};

export default Sider;
