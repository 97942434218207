import { Divider, message, Spin, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import axios_reports from "../../../axios/axios-reports";
import "moment/locale/hr"; // without this line it didn't work
moment.locale("hr");



 

const DownTimes = ({start, end, shift}) => {

    const [downTimes, setDownTimes] = React.useState(null);
    const [loading, set_loading] = React.useState(true);
    const {id} = useParams();

    const getDownTimes = async () => {
        set_loading(true);
    let token = await localStorage.getItem("token");
    axios_reports({ token })
      .get(`all_downtimes/`, {
        params: {device: id, start: start, end: end, shift: shift},
      })
      .then((res) => {
        setDownTimes(res.data);
        set_loading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Dogodila se greska.");
        set_loading(false);
      });
    }

    React.useEffect(() => {
        getDownTimes();
    }, [start, end, shift]);

  


    return <div style={{width: "100%"}} >
        <div className="spaceBetweenRow" style={{justifyContent: "center"}}>
            {loading && <Spin size="small" />}
            </div>
          
        <div className="spaceBetweenRow" style={{ alignItems: 'center', marginBottom: 12 }}>
            <Typography.Title level={5} style={{ margin: 0 }}>Zaustavljanja ukupno:</Typography.Title>
            <Typography.Title level={5} style={{ margin: 0 }}>{downTimes?.total_duration}</Typography.Title>
        </div>

        <div>
            {downTimes?.details.map((downTime, index) => (
                <div key={index} style={{padding: "6px 12px", backgroundColor: index % 2 ? "rgba(0,0,0,0.05)" : undefined}}>
                    <div  />
                <div key={index} className="spaceBetweenRow">
                    <div style={{display: "flex", alignItems: "center"}}>
                    <strong style={{paddingTop: 0, fontSize: 18, marginRight: 12}}>{downTime.type}</strong>
                    <Typography.Text>{downTime.start} - {downTime.end}</Typography.Text>
                    </div>
                    <Typography.Text>{downTime.duration}</Typography.Text>
                </div>
                </div>
            ))}
        </div>
        <div  className="spaceBetweenRow" >
            {(!downTimes || downTimes?.details.length === 0 )&& <Typography.Text style={{
                width: "100%",
                textAlign: "center"
            }}>Nema podataka</Typography.Text>}
        </div>
    </div>
}


export default DownTimes;