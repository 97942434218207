import React from "react";
import { Text, View, Pressable, StyleSheet, Dimensions } from "react-native";

// Colors
import { colors } from "../../../../styles/colors";

// Constants
let { width, height } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}
const BUTTON_WIDTH = (width - 40) / 2 - 12;

export default function Button({ label = "", value = "", color, onPress }) {
  return (
    <Pressable onPress={onPress}>
      <View style={[styles.container, { backgroundColor: color }]}>
        {/* Dots */}
        <View style={styles.dots}>
          <View style={styles.dot} />
          <View style={styles.dot} />
          <View style={styles.dot} />
        </View>

        {/* Label */}
        <Text style={styles.label}>{label}</Text>

        {/* Value */}
        <Text style={styles.value}>{value}</Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    width: BUTTON_WIDTH,
    height: 84,
    padding: 8,
    backgroundColor: colors.lightGrey,
    borderRadius: 8,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    textAlign: "left",
    width: "100%",
    fontSize: 10,
    fontWeight: 300,
    fontFamily: 'Poppins',
    color: colors.grey,
  },
  value: {
    textAlign: "left",
    width: "100%",
    fontSize: 20,
    marginTop: -2,
    marginBottom: -12,
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  dots: {
    width: 8,
    height: 10,
    position: "absolute",
    top: 12,
    right: 8,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dot: {
    width: 2,
    height: 2,
    backgroundColor: colors.grey,
    opacity: 0.65,
    borderRadius: 8,
  },
});
