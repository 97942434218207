import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Pressable,
  TextInput,
  ActivityIndicator,
  Keyboard,
  ScrollView,
} from "react-native";

// Components
import Header from "../../components/Header";
import ErrorRequestModal from "../../components/ErrorRequestModal";

// Styles
import { colors } from "../../styles/colors";

import { useDispatch, useSelector } from "react-redux";
import { SET_S_PLAN } from "../../redux/modules/Reports/actions";

// Constants
let { width, height } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}

export default function SalesForm({ toggleDrawer, set_toggleDrawer }) {
  const {
    active_params,
    saveSalesPlan,
    reports: {
      data: { sales },
    },
  } = useSelector((state) => state.Reports);

  const setInitialPlan = () => {
    return {
      "1": {
        name: "Siječanj",
        sales_value: sales.plan?.months["1"]?.sales_value,
        cost_value: sales.plan?.months["1"]?.cost_value,
      },
      "2": {
        name: "Veljača",
        sales_value: sales.plan?.months["2"]?.sales_value,
        cost_value: sales.plan?.months["2"]?.cost_value,
      },
      "3": {
        name: "Ožujak",
        sales_value: sales.plan?.months["3"]?.sales_value,
        cost_value: sales.plan?.months["3"]?.cost_value,
      },
      "4": {
        name: "Travanj",
        sales_value: sales.plan?.months["4"]?.sales_value,
        cost_value: sales.plan?.months["4"]?.cost_value,
      },
      "5": {
        name: "Svibanj",
        sales_value: sales.plan?.months["5"]?.sales_value,
        cost_value: sales.plan?.months["5"]?.cost_value,
      },
      "6": {
        name: "Lipanj",
        sales_value: sales.plan?.months["6"]?.sales_value,
        cost_value: sales.plan?.months["6"]?.cost_value,
      },
      "7": {
        name: "Srpanj",
        sales_value: sales.plan?.months["7"]?.sales_value,
        cost_value: sales.plan?.months["7"]?.cost_value,
      },
      "8": {
        name: "Kolovoz",
        sales_value: sales.plan?.months["8"]?.sales_value,
        cost_value: sales.plan?.months["8"]?.cost_value,
      },
      "9": {
        name: "Rujan",
        sales_value: sales.plan?.months["9"]?.sales_value,
        cost_value: sales.plan?.months["9"]?.cost_value,
      },
      "10": {
        name: "Listopad",
        sales_value: sales.plan?.months["10"]?.sales_value,
        cost_value: sales.plan?.months["10"]?.cost_value,
      },
      "11": {
        name: "Studeni",
        sales_value: sales.plan?.months["11"]?.sales_value,
        cost_value: sales.plan?.months["11"]?.cost_value,
      },
      "12": {
        name: "Prosinac",
        sales_value: sales.plan?.months["12"]?.sales_value,
        cost_value: sales.plan?.months["12"]?.cost_value,
      },
    };
  };

  const [plan, setPlan] = useState(setInitialPlan());

  const [valueFromInput, setValueFromInput] = useState();
  const [successVisible, setSuccessVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setInitialPlan(sales.plan);
  }, [sales.plan]);

  const setPlanValue = (valueIndex) => {
    let tmp = { ...plan };

    let tmpValues = valueIndex.split("|");


    if (tmpValues[1] === "A") {
      tmp[+tmpValues[0] + 1].sales_value = valueFromInput;
    } else {
      tmp[+tmpValues[0] + 1].cost_value = valueFromInput;
    }

    setPlan(tmp);
  };

  const showSuccess = () => {
    setSuccessVisible(true);
    setTimeout(() => {
      setSuccessVisible(false);
    }, 2100);
  };

  const _savePlan = () => {
    Keyboard.dismiss();
    dispatch({
      type: SET_S_PLAN,
      data: { year: active_params.year, months: plan },
      successCallback: () => showSuccess(),
    });
  };

  return (
    <View style={styles.container}>
      {/* Header */}
      <Header
        toggleDrawer={toggleDrawer}
        set_toggleDrawer={set_toggleDrawer}
        name="Plan prodaje"
        canGoBack={true}
      />

      {/* Wrapper */}
      <View style={styles.wrapper}>
        <Text
          style={{
            fontSize: 18,
            fontWeight: 500,
            fontFamily: 'Poppins',
            textAlign: "left",
            width,
            marginLeft: 32,
            paddingVertical: 14,
            // paddingBottom: 5
          }}
        >
          {"Plan prodaje za: " + active_params.year}
        </Text>
        <div style={{ overflow: "scroll", height: "99vh" }}>
          {Object.keys(plan).map((key, index) => (
            <View key={index}>
              <View
                style={{
                  marginTop: 12,
                  display: "flex",
                  flexDirection: "row",
                  justifayContent: "space-between",
                }}
              >
                <View style={styles.valuesColumn}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontWeight: 500,
                          fontFamily: 'Poppins',
                          marginBottom: 16,
                        }}
                      >
                        {plan[key].name}{" "}
                      </Text>

                      <Text style={styles.label}>Prodajna vrijednost</Text>
                      <TextInput
                        // onSubmitEditing={() => {
                        //   elRefs[index + "B"].current?.focus();
                        // }}
                        onBlur={() => {
                          setPlanValue(index + "|A");
                        }}
                        // ref={elRefs[index + "A"]}
                        // placeholder="Prodajna vrijednost"
                        defaultValue={plan[index + 1].sales_value?.toString()}
                        style={styles.textInput}
                        onChangeText={(value) => {
                          setValueFromInput(value.replace(",", "."));
                        }}
                        returnKeyType="done"
                        autoCapitalize="none"
                        keyboardType="decimal-pad"
                        onFocus={() => {
                          const value =
                            plan[(1 + index).toString()].sales_value;
                          setValueFromInput(value);
                        }}
                      />
                    </View>
                  </View>
                </View>
                <View style={styles.valuesColumn}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontWeight: 500,
                          fontFamily: 'Poppins',
                          marginBottom: 16,
                          opacity: 0,
                        }}
                      >
                        {plan[key].name}{" "}
                      </Text>

                      <Text style={styles.label}>Nabavna vrijednost</Text>
                      <TextInput
                        // onSubmitEditing={() => {
                        //   if (index + 1 + "A" !== "12A")
                        //     elRefs[index + 1 + "A"].current?.focus();
                        // }}
                        onBlur={() => {
                          setPlanValue(index + "|B");
                        }}
                        // ref={elRefs[index + "B"]}
                        // placeholder="Nabavna vrijednost"
                        defaultValue={plan[index + 1].cost_value?.toString()}
                        onChangeText={(value) => {
                          setValueFromInput(value.replace(",", "."));
                        }}
                        style={styles.textInput}
                        paddingLeft={6}
                        returnKeyType="done"
                        autoCapitalize="none"
                        keyboardType="decimal-pad"
                        onFocus={() => {
                          const value = plan[(1 + index).toString()].cost_value;
                          setValueFromInput(value);
                        }}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          ))}
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              paddingVertical: 20,
              paddingTop: 40,
            }}
          >
            {successVisible ? (
              <Text
                style={[
                  styles.sectionName,
                  {
                    color: "#4CAF50",
                    // fontWeight: 600
                  },
                ]}
              >
                Uspješno ste spremili plan!
              </Text>
            ) : (
              <Pressable onPress={_savePlan}>
                {saveSalesPlan.status === "loading" ? (
                  <ActivityIndicator color={colors.darkGrey} size="large" />
                ) : (
                  <Text style={styles.saveText}>Spremi</Text>
                )}
              </Pressable>
            )}
          </View>
          <View style={{ height: 270 }}></View>
        </div>
      </View>
      <ErrorRequestModal />
    </View>
  );
}

const styles = StyleSheet.create({
  saveText: {
    color: colors.lightGrey,
    borderRadius: 8,
    backgroundColor: colors.darkGrey,
    width: width - 32,
    textAlign: "center",
    paddingVertical: 18,
    paddingTop: 20,
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  valuesColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: width * 0.5,
  },
  textInput: {
    width: width / 2 - 32,
    borderWidth: 1,
    borderColor: "#cdcdcd",
    fontWeight: 400,
    fontFamily: 'Poppins',
    paddingVertical: 7,
    paddingTop: 9,
    paddingLeft: 6,
    borderRadius: 4,
    marginTop: 0,
    marginBottom: 10,
    color: colors.black,
  },
  container: {
    flex: 1,
    backgroundColor: colors.black,
    // paddingTop: 30,
    alignItems: "center",
  },
  wrapper: {
    width,
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: "auto",
    alignItems: "center",
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  text: {
    fontSize: 24,
    marginTop: 4,
    fontWeight: 500,
    fontFamily: 'Poppins',
    textAlign: "center",
    color: colors.black,
  },
  label: {
    position: "absolute",
    fontSize: 12,
    top: 24,
    left: 8,
    zIndex: 10,
    paddingHorizontal: 4,
    paddingVertical: 2,
    backgroundColor: colors.white,
    fontWeight: 300,
    fontFamily: 'Poppins',
  },
});
