import React from "react";
import { Text, View, StyleSheet, Dimensions, Pressable } from "react-native";
import { formatPrice } from "../../../helpers/priceFormater";

// Colors
import { colors } from "../../../styles/colors";

// Constants
let { width } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}

export default function Button({
  label = "",
  value = "",
  color,
  calculation,
  onPress,
}) {
  return (
    <Pressable onPress={onPress}>
      <View style={[styles.container, { backgroundColor: color }]}>
        {/* Dots */}

        {/* Label */}
        <Text style={styles.label}>{label}</Text>

        {/* Value */}
        <View
          style={{
            width,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingHorizontal: 21,
          }}
        >
          <Text style={styles.value}>{formatPrice(value)}</Text>
          <Text>{"  "}</Text>
          {calculation && <Text style={styles.calculation}>{calculation}</Text>}
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 84,
    padding: 8,
    backgroundColor: colors.lightGrey,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: 16,
  },
  label: {
    textAlign: "left",
    width: "100%",
    fontSize: 10,
    fontWeight: 300,
    fontFamily: 'Poppins',
    color: colors.grey,
    marginTop: 4,
  },
  value: {
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  calculation: {
    fontSize: 15,
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  dots: {
    width: 8,
    height: 10,
    position: "absolute",
    top: 12,
    right: 8,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dot: {
    width: 2,
    height: 2,
    backgroundColor: colors.grey,
    opacity: 0.65,
    borderRadius: 8,
  },
});
