import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  watcherLogout,
  watcherSetTokenFromLocaleStorage,
  watcherGetUserByToken,
  watcherLogin,
} from "./modules/Users/user";
import {
  watcherGetReports,
  watcher_setProductionPlan,
  watcher_setSalesPlan,
} from "./modules/Reports";

// create the saga middleware
export const sagaMiddleware = createSagaMiddleware();

const initialState = {};

const middlewares = [thunk];

const _createStore = compose(
  applyMiddleware(...middlewares, sagaMiddleware),
  // composeWithDevTools()
)(createStore);

const store = _createStore(rootReducer, initialState);

sagaMiddleware.run(watcherLogout);
sagaMiddleware.run(watcherLogin);
sagaMiddleware.run(watcherGetUserByToken);
sagaMiddleware.run(watcherSetTokenFromLocaleStorage);
sagaMiddleware.run(watcher_setProductionPlan);
sagaMiddleware.run(watcher_setSalesPlan);

sagaMiddleware.run(watcherGetReports);

export const rrfProps = {
  dispatch: store.dispatch,
};

export default store;
