import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Dimensions, View, StyleSheet } from "react-native-web";
import { useDispatch } from "react-redux";
import { getApiEndpoint } from "../../axios/endpoints";
import Header from "../../components/Header";
import LoadingDisplay from "../../components/LoadingDisplay";
import { LOGOUT } from "../../redux/modules/Users/actions";
import { colors } from "../../styles/colors";
import MachineCard from "./MachineCard";
var mqtt = require("mqtt");

let { width, height } = Dimensions.get("screen");

const LiveStrojevi = ({toggleDrawer, set_toggleDrawer}) => {


    const [liveMachines, set_liveMachines] = useState([]);
    const [loading, set_loading] = useState(false);
    const dispatch = useDispatch();
    const stateRef = useRef();

    stateRef.current = liveMachines;

    const updateMachines = (parsedMessage, topic) => {
        let machineName = topic.split("/");
        let tmp = [];
        if(Array.isArray(stateRef.current)){
            tmp = [...stateRef.current]
        }
        //index niza gdje je lista strojeva
        let index = tmp.findIndex(x => x.device_id === machineName[machineName.length -1]);

        //izdvojiti metrike koji su za brzinu
        let metrics = parsedMessage.metrics.filter(x => x.name === "Speed");

        //ukoliko postoji stroj u nizu i ukoliko postoji metrik koji je vezan za brzinu uzmi zadnju vrijednost
        if(index !== -1 && metrics.length > 0){
          let value = metrics[metrics.length -1].value;
          let time = metrics[metrics.length -1].timestamp
          if(typeof value === "number"){
            value = value.toFixed(1);
          }
            tmp[index] = {
                ...tmp[index],
               value,
               time
            }
            set_liveMachines(tmp);
        }
    }


    const connectToMqtt = async () => {
        let client = null;
        try {
        client = await mqtt.connect(process.env.REACT_APP_WS_URL,  {reconnectPeriod: 3000,
        username: process.env.REACT_APP_MQTT_USERNAME,
        password: process.env.REACT_APP_MQTT_PASSWORD,
      }) 
        client.on("connect", function (response) {
            console.log("Spojeno na strojeve!");
          });
          client.on("message", (topic, message) => {
            try {
                updateMachines(JSON.parse(message), topic)
            } catch (error) {
                console.log(error);
            }
 
          });

          client.subscribe("spBv1.0/Ignition/DDATA/Siemens/#", (tmp, message) => {
          });
            
        } catch (error) {
            console.log(error)
        }
    }
 

    useEffect(() => {
        getLiveMachines();
    }, [])

    const getLiveMachines = async () => {
        set_loading(true)

        let token = await localStorage.getItem("token");

        try {
            let res = await axios.get(`${getApiEndpoint()}reports/live_machines/`, {
                headers: {
                    Authorization: 'Token ' + token,
                }
            })
            set_liveMachines(Array.isArray(res.data) ? res.data : []);
            connectToMqtt();
        } catch (error) {
            if(error?.response?.status === 401){
                dispatch({type: LOGOUT});
                return;
            }

            alert("Dogodila se greska kod dohvata...")
        }

        set_loading(false)
    }


    const _loadingDisplay = loading === "loading" && <LoadingDisplay />;




    const sortedByAlphabet = liveMachines?.sort((a, b) => a["device_name"].localeCompare(b["device_name"]))

    return <>
         <View style={styles.container}>
        {_loadingDisplay}
        {/* Header */}
        <Header
          name="Live strojevi"
          toggleDrawer={toggleDrawer}
          set_toggleDrawer={set_toggleDrawer}
        />
        </View>
        <div style={{height: 27}}  />
        <div style={{padding: "0 6px", height: "calc(100vh - 99px)", overflowY: "scroll"}} >
        {sortedByAlphabet.map((x,i) => <MachineCard key={i} item={x} />)}
        <div style={{height: 27}}  />
        
        </div>
    </>
}


export default LiveStrojevi;


const styles = StyleSheet.create({
    container: {
      flex: 1,
      // paddingTop: 30,
      backgroundColor: colors.black,
      alignItems: "center",
    },
  
    contentContainerStyle: {
      alignItems: "center",
      height: "72vh",
    },
    text: {
      fontSize: 24,
      marginTop: 4,
      fontWeight: 500,
      fontFamily: "Poppins",
      textAlign: "center",
    },
    pressable: {
      width: 100,
      height: 40,
      backgroundColor: colors.lightGrey,
    },
    flexRow: {
      width: width - 40,
      marginHorizontal: 20,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });