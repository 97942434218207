import React from "react";
import { View, Text, Pressable } from "react-native";

// Styles
import { colors } from "../styles/colors";

import IMG_LEFT from "../assets/icons/chevron-left.png";
import CLOSE from "../assets/icons/close.png";
import IMG_OPEN from "../assets/icons/menu.png";
import HitSlop from "./HitSlop";

import { Dimensions } from "react-native";
import { useHistory } from "react-router-dom";

// Constants

let { width, height } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}

export default function Header({
  set_toggleDrawer,
  subname,
  name,
  canGoBack = false,
  toggleDrawer,
}) {
  // Variables

  const history = useHistory();

  return (
    <View
      style={
        styles.container
        // { paddingTop: Platform.OS === "android" ? 21 : 48 },
      }
    >
      <View style={styles.flexRow}>
        {/* Back button */}
        {canGoBack && (
          <Pressable
            style={styles.backButton}
            onPress={() => {
              history.goBack();
              }}
            hitSlop={50}
          >
            <img src={IMG_LEFT} style={styles.back} />
          </Pressable>
        )}

        {/* Route name */}
        <View style={styles.routeNameView}>
        <Text style={styles.routeName}>{name}</Text>
          <Text style={styles.routeName}>{subname}</Text>
        </View>
      </View>

      {/* Toggle navigation button */}
      <HitSlop onClick={() => set_toggleDrawer(!toggleDrawer)}>
        {toggleDrawer ? (
          <img src={CLOSE} style={styles.menu} />
        ) : (
          <img src={IMG_OPEN} style={styles.menu} />
        )}
      </HitSlop>
    </View>
  );
}

const styles = {
  container: {
    width,
    paddingHorizontal: 20,
    paddingVertical: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#000",
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  routeNameView: {
    flexDirection: "row",
    alignItems: "center",
  },
  routeName: {
    marginTop: 2,
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Poppins',
    marginRight: 8,
    color: colors.white,
  },
  menu: {
    width: 24,
    height: 24,
  },
  back: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  backButton: {
    paddingVertical: 8,
    paddingHorizontal: 10,
    marginLeft: -10,
  },
};
