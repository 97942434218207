import * as endpoints from "../axios/endpoints";
var mqtt = require("mqtt");
var client = null;

export async function getMqttClient(topics, updateState) {
  if (client) {
    client.end();
  }
  client = await mqtt.connect(process.env.REACT_APP_WS_URL,
  {
    reconnectPeriod: 3000,
    username: process.env.REACT_APP_MQTT_USERNAME,
    password: process.env.REACT_APP_MQTT_PASSWORD
  }
  );
  console.log("SPAJAM", process.env.REACT_APP_WS_URL);

  client.on("connect", function (response) {
    console.log("Spojeno!");
  });
  client.on("message", (topic, message) => {
    updateState(message);
  });
  if (topics) {
    for (let i = 0; i < topics.length; i++) {
      try {
        client.subscribe(topics[i], (tmp, message) => {
          console.log(message);
        });
      } catch (error) {
        console.log("pukao suscribe", error);
      }
    }
  }

  return client;
}
