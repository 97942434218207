import { Typography } from "antd";
import React from "react";
import Information from "../../components/Information";

const InfoCardMaterial = ({materialItem}) => {


    return (
        <div>
            <Typography.Title level={5} style={{fontSize: 18, fontWeight: "bold"}}>{materialItem.full_material.name}</Typography.Title>
            <Information type={"waste"} _width={"100%"} value={materialItem.planned_waste} label={"Planirani"}  />
            <Information type={"waste"} _width={"100%"} value={materialItem.unplanned_waste} label={"Neplanirani"}  />
        </div>
    )
}

export default InfoCardMaterial;