import React, { useEffect } from "react";
import { Dimensions, View, StyleSheet } from "react-native-web";
import Header from "../../components/Header";
import { colors } from "../../styles/colors";
import { Typography } from "antd";

let { width, height } = Dimensions.get("screen");

const NotFound = ({toggleDrawer, set_toggleDrawer}) => {
  

    return <>
         <View style={styles.container}>
        {/* Header */}
        <Header
          name="NotFound"
          toggleDrawer={toggleDrawer}
          set_toggleDrawer={set_toggleDrawer}
        />
        </View>
        <Typography.Title level={5} style={{fontSize: 18, fontWeight: "bold", color: "black", marginTop: 36, textAlign: "center", width: "100%"}}>Nemate permisiju za pristup ovoj aplikaciji!</Typography.Title>

    </>
}

export default NotFound;


const styles = StyleSheet.create({
    container: {
      flex: 1,
      // paddingTop: 30,
      backgroundColor: colors.black,
      alignItems: "center",
    },
  
    contentContainerStyle: {
      alignItems: "center",
      height: "72vh",
    },
    text: {
      fontSize: 24,
      marginTop: 4,
      fontWeight: 500,
      fontFamily: "Poppins",
      textAlign: "center",
    },
    pressable: {
      width: 100,
      height: 40,
      backgroundColor: colors.lightGrey,
    },
    flexRow: {
      width: width - 40,
      marginHorizontal: 20,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });