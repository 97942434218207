const colors = {
    white: "#FFFFFF",
    black: "#000000",
    orange: "#F5EEEA",
    green: "#F4F9FA",
    grey: "#434343",
    darkGrey: "#191919",
    lightGrey: "#F0F0F0"
}

export { colors }