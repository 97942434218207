import React from "react";
import { View, Text, Pressable } from "react-native";

import { isTechnicianPrimary } from "../../../../helpers/getSpecValueFromOrder";

import IMG_CLOCK_EMPTY from "../../../../assets/icons/clock-empty.png";
import IMG_CLOCK_FILED from "../../../../assets/icons/clock-filled.png";
import IMG_USER_BLACK from "../../../../assets/icons/user-black.png";

export default function OrderUsers(props) {
  const { commandsVisible } = props;
  const top = props.technicians.filter((item) => item.is_primary);

  const bottom = props.technicians.filter((item) => !item.is_primary);

  return (
    <View style={styles.container}>
      {bottom.length || top.length ? (
        <Text style={styles.title}>Korisnici na nalogu: </Text>
      ) : null}

      {commandsVisible && isTechnicianPrimary(props.order, props.currentUser) && (
        <Pressable
          style={({ pressed }) => [
            styles.toggleButton,
            { backgroundColor: pressed ? "#4675ffbb" : "#4675ff" },
          ]}
          onPress={() => props.toggleAddWorker(true, props.order)}
        >
          <Text style={styles.toggleButtonText}>+</Text>
        </Pressable>
      )}

      {top.map((tech) => (
        <Text key={tech.id} style={styles.busy}>
          <img style={styles.icon} src={IMG_USER_BLACK} />
          {"  "}
          {tech.account.full_name} {tech.is_working ? null : null}{" "}
        </Text>
      ))}

      {bottom.length && top.length ? (
        <View style={styles.divider}></View>
      ) : null}

      {bottom.map((tech) => (
        <Text key={tech.id} style={styles.busy}>
          {tech.account.status === "busy" ? (
            <img style={styles.icon} src={IMG_CLOCK_FILED} />
          ) : (
            <img style={styles.icon} src={IMG_CLOCK_EMPTY} />
          )}
          {"  "}
          {tech.account.full_name}{" "}
        </Text>
      ))}
    </View>
  );
}

const styles = {
  container: {
    paddingHorizontal: 20,
    marginBottom: 40,
  },
  toggleButton: {
    position: "absolute",
    width: 40,
    height: 40,
    backgroundColor: "#4675ff",
    borderRadius: 50,
    top: 0,
    right: 20,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  toggleButtonText: {
    color: "#fff",
    fontWeight: 400,
    fontFamily: 'Poppins',
    fontSize: 24,
    marginTop: 7,
  },
  divider: {
    borderBottomWidth: 1,
    borderColor: "#00000020",
    marginVertical: 8,
  },
  icon: {
    width: 14,
    height: 14,
  },
  title: {
    fontSize: 18,
    marginBottom: 8,
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  busy: {
    fontSize: 14,
    paddingVertical: 2,
    fontWeight: 400,
    fontFamily: 'Poppins',
  },
};
