import React, { useState, useRef } from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  SafeAreaView,
  FlatList,
  Text,
} from "react-native";

import {useSelector} from "react-redux";


import { getBackgroundColorOfOrder } from "../../../../helpers/styling";

import OrderDetails from "../OrderDetails";
import IMG_BLOCK_EMPTY from "../../../../assets/icons/empty_box.png";

let { width, height } = Dimensions.get("screen");
if(width > 600){
  width = 600;
}

function InBowl({ /* State */ orders }) {
  const flatListRef = useRef();

  let { data, status } = useSelector((state) => state.Reports.maintenances);

  data = data
    .filter((item) => item.status === "in_bowl")
    .sort((a, b) => a.priority - b.priority);

  const renderItem = (item, index) => {
    return (
      <View style={{ backgroundColor: getBackgroundColorOfOrder(item, index) }}>
        <OrderDetails data={item} />
      </View>
    );
  };

  return (
    <>
      <View style={styles.container}>
        {data.length === 0 ? (
          <View style={styles.noData}>
            <img style={styles.empty} src={IMG_BLOCK_EMPTY} />
            <Text style={{fontWeight: 500, fontFamily: 'Poppins', fontSize: 22, paddingTop: 12, marginBottom: -6}}>Nema naloga</Text>
            <Text style={{fontWeight: 300, fontFamily: 'Poppins', fontSize: 12 }}>Izgleda da nema nedodjeljenih naloga</Text>
          </View>
          
        ) : null}

        <SafeAreaView>
          <FlatList
            ref={flatListRef}
            data={data}
            renderItem={({ item, index }) => renderItem(item, index)}
            keyExtractor={(item) => item.id.toString()}
          />
      
          {/* Padding bottom */}
          <View style={{ height: 160 }} />

        </SafeAreaView>
      </View>
    </>
  );
}

const styles = {
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    height: height - 136,
    width: width,
    backgroundColor: "#fff",
  },
  noData:{
    flexDirection: "column",
    alignItems: "center",
    marginTop: 60
  },
  empty:{
    width: width * .2,
    height: width * .2
  },
  buttonsView: {
    flexDirection: "row",
    paddingHorizontal: 20,
    justifyContent: "space-between",
  },
  separator: {
    width: width - 40,
    height: 0.3,
    borderWidth: 1,
    borderColor: "#00000060",
    borderStyle: "dashed",
    borderRadius: 1,
    marginLeft: 20,
    marginBottom: 32,
  },
  errorBanner: {
    position: "absolute",
    left: 20,
    width: width - 40,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: "#000",
    textAlign: "center",
    padding: 28,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#fc1c0d",
    backgroundColor: "#fc9992",
    marginBottom: 32,
    zIndex: 1234,
    top: 20,
  },
};

export default InBowl;
