import React from "react";
import {
  View,
  Text,
  Dimensions,
} from "react-native";
import moment from "moment";
import "moment/locale/hr";

import IMG_IN_PROGRESS from "../../../../assets/icons/in_progress.png"

let { width, height } = Dimensions.get("window");

if(width > 600){
  width = 600;
}

export default function OrderDetails({
  data: {
    name,
    categories,
    type,
    items,
    status,
    id,
    created_at,
    planned_start,
    description,
    machine_run,
    estimated_time,
    estimated_time_minute,
    creator,
    real_start,
    real_end,
  },
}) {
  const type_color = type.name === "Incidentno" ? "#ff1845" : "#000";

  const created_at_formatted = moment(created_at).locale("hr").format("LLL");
  const planned_start_formatted = moment(planned_start)
    .locale("hr")
    .format("LLL");

  function validate(item) {
    if (item == null || item === "") {
      return "---";
    } else {
      return item;
    }
  }

  const validatedCategories =
    categories.length >= 3
      ? categories.map((item) => item.name.substring(0, 3) + ". ")
      : categories.map((item) => item.name + " ");

  if (estimated_time && estimated_time < 10) {
    estimated_time = "0" + estimated_time;
  }
  if (estimated_time_minute && estimated_time_minute < 10) {
    estimated_time_minute = "0" + estimated_time_minute;
  }

  return (
    <View style={styles.container}>
      <View style={styles.flexRow}>
        {status === "in_progress" && (
          <img
            style={styles.icon}
            src={IMG_IN_PROGRESS}
          />
        )}
        <Text
          style={[
            styles.orderName,
            {
              paddingRight: status === "in_progress" ? 20 : 0,
              color: type_color,
            },
          ]}
        >
          {validate(name)}
        </Text>
      </View>
      <View style={styles.infoView}>
        <View style={styles.infoItem}>
          <Text style={styles.label}>Stroj: </Text>
          <Text style={styles.value}>
            {items.length
              ? items.map(
                  (item) =>
                    `${validate(item.name)} ${validate(item.info.model)}`
                )
              : "---"}
          </Text>
        </View>
        <View style={styles.infoItem}>
          <Text style={styles.label}>Kategorije: </Text>
          <Text style={styles.value}>
            {categories.length ? validatedCategories : "---"}
          </Text>
        </View>
        <View style={styles.infoItem}>
          <Text style={styles.label}>Tip: </Text>
          <Text style={[styles.value, { color: type_color }]}>
            {validate(type.name)}
          </Text>
        </View>
        <View style={styles.infoItem}>
          <Text style={styles.label}>Lokacija: </Text>
          <Text style={styles.value}>---</Text>
        </View>
        <View style={styles.infoItem}>
          <Text style={styles.label}>ID: </Text>
          <Text style={styles.value}>{validate(id)}</Text>
        </View>
        <View style={styles.infoItem}>
          <Text style={styles.label}>Prijavio: </Text>
          <Text style={styles.value}>
            {creator ? creator.full_name : "---"}
          </Text>
        </View>
        <View style={styles.infoItem}>
          <Text style={styles.label}>Datum stvaranja: </Text>
          <View style={styles.flexRow}>
            <Text style={styles.value}>{created_at_formatted} h</Text>
          </View>
        </View>
        <View style={styles.infoItem}>
          <Text style={styles.label}>Planirani start: </Text>
          <View style={styles.flexRow}>
            <Text style={styles.value}>
              {validate(planned_start_formatted)} h
            </Text>
          </View>
        </View>
        {status === "finished" || status === "in_progress" ? (
          <View style={styles.infoItem}>
            <Text style={styles.label}>Stvarni početak: </Text>
            <View style={styles.flexRow}>
              <Text style={styles.value}>
                {validate(moment(real_start).locale("hr").format("LLL"))} h
              </Text>
            </View>
          </View>
        ) : null}
        {status === "finished" ? (
          <View style={styles.infoItem}>
            <Text style={styles.label}>Stvarni kraj: </Text>
            <View style={styles.flexRow}>
              <Text style={styles.value}>
                {validate(moment(real_end).locale("hr").format("LLL"))} h
              </Text>
            </View>
          </View>
        ) : null}
        <View style={[styles.infoItem, { alignItems: "center" }]}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={styles.label}>Potrebno vrijeme: </Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={[styles.value]}>
              {(estimated_time || (estimated_time_minute ? "00" : "--")) +
                ":" +
                (estimated_time_minute || (estimated_time ? "00" : "--")) +
                " h"}
            </Text>
          </View>
        </View>
        <View style={styles.infoItem}>
          <Text style={styles.label}>Status stroja: </Text>
          <View style={styles.flexRow}>
            <Text style={[styles.value]}>
              {machine_run ? "Aktivan" : "Neaktivan"}
            </Text>
          </View>
        </View>
        <View style={styles.infoItem}>
          <Text style={styles.label}>Opis: </Text>
          <View style={styles.flexRow}>
            <Text style={[styles.value]}>{validate(description)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = {
  icon: {
    width: 14,
    height: 14,
  },
  container: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: width,
    padding: 20,
    marginBottom: 0,
  },
  detailsButton: {
    width: 92,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#4675ff",
    marginTop: 16,
    borderRadius: 4,
  },
  detailsButtonText: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 12,
    paddingTop: 2,
    color: "#fff",
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  icon: {
    width: 24,
    height: 24,
    marginTop: 5,
    marginRight: 6,
  },
  orderName: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 22,
  },
  infoView: {
    marginVertical: 20,
  },
  infoItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: width - 40,
    paddingVertical: 2,
  },
  label: {
    fontWeight: 400,
    fontFamily: 'Poppins',
    opacity: 0.6,
    paddingRight: 16,
  },
  value: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 14,
    lineHeight: 24,
    textAlign: "justify",
    maxWidth: width - 100,
  },
  CTAview: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: width - 40,
  },
  orderInfoCTA: {
    borderWidth: 1,
    borderColor: "#4675ff",
    borderRadius: 4,
    width: 92,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  takeOrderCTA: {
    borderWidth: 1,
    borderColor: "#4675ff",
    borderRadius: 4,
    backgroundColor: "#4675ff",
    width: 92,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  orderInfoCTAText: {
    color: "#4675ff",
    fontWeight: 500,
    fontFamily: 'Poppins',
    marginTop: 3,
  },
  takeOrderCTAText: {
    color: "#ffffff",
    fontWeight: 500,
    fontFamily: 'Poppins',
    marginTop: 3,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: 500,
    fontFamily: 'Poppins',
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  actionSheetContainer: {
    padding: 20,
    height: height * 0.9,
    maxHeight: height * 0.9,
  },
  actionSheetTitle: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 24,
    marginBottom: 16,
  },
  actionSheetDivider: {
    width: width - 40,
    height: 0.1,
    opacity: 0.3,
    borderStyle: "dashed",
    borderWidth: 0.6,
    borderRadius: 1,
    marginBottom: 20,
  },
  actionSheetLabel: {
    fontWeight: 400,
    fontFamily: 'Poppins',
    fontSize: 18,
    color: "#00000099",
    paddingVertical: 12,
  },
  actionSheetConfirm: {
    width: width - 40,
    height: 60,
    marginTop: 32,
    backgroundColor: "#4675ff",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  actionSheetConfirmDisabled: {
    width: width - 40,
    height: 60,
    marginTop: 32,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#00000080",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  actionSheetConfirmText: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 16,
    color: "#FFF",
    marginTop: 4,
  },
  actionSheetConfirmTextDisabled: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 16,
    color: "#00000080",
    marginTop: 4,
  },
};
