import React, { useRef } from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  SafeAreaView,
  FlatList,
  Text,
  Image,
} from "react-native";


import { sortWorkingOrderToTop } from "../../../../helpers/sorting";

// import EmptyState from "../../../components/EmptyState";
import OrderDetails from "../OrderDetails";
import OrderTimeView from "../OrderTimeView";
import OrderUsers from "../OrderUsers";
import IMG_BLOCK_EMPTY from "../../../../assets/icons/empty_box.png";

import { getBackgroundColorOfOrder } from "../../../../helpers/styling";

import { useSelector } from "react-redux";

let { width, height } = Dimensions.get("screen");
if(width > 600){
  width = 600;
}

function InProgress({ /* State */ orders }) {
  const flatListRef = useRef();

  let { data, status } = useSelector((state) => state.Reports.maintenances);

  data = sortWorkingOrderToTop(
    data.filter(
      (item) => item.status === "in_progress" || item.status === "waiting"
    )
  );

  const renderItem = (item, index) => {
    return (
      <>
        <View
          style={{ backgroundColor: getBackgroundColorOfOrder(item, index) }}
        >
          <OrderDetails data={item} />
          <OrderUsers order={item} technicians={item.technicians} />
          <OrderTimeView status={item.status} real_start={item.real_start} />
          <View style={styles.separator}></View>
        </View>
      </>
    );
  };

  return (
    <>
      <View style={styles.container}>
        {data.length === 0 ? (
          <View style={styles.noData}>
            <img style={styles.empty} src={IMG_BLOCK_EMPTY} />
            <Text
              style={{
                fontWeight: 500,
                fontFamily: 'Poppins',
                fontSize: 22,
                paddingTop: 12,
                marginBottom: -6,
              }}
            >
              Nema naloga
            </Text>
            <Text style={{ fontWeight: 300, fontFamily: 'Poppins', fontSize: 12 }}>
              Izgleda da nema naloga u tijeku
            </Text>
          </View>
        ) : // <EmptyState
        //   img={require("../../../../assets/icons/empty-boxx.png")}
        //   title="Niste preuzeli nijedan nalog."
        //   description="Izaberite nalog iz nedodjeljenih naloga."
        // />
        null}
        <View
          style={{
            backgroundColor: "#fff",
            width: width,
            position: "absolute",
            zIndex: 99,
          }}
        ></View>
        <SafeAreaView>
          <FlatList
            ref={flatListRef}
            data={data}
            renderItem={({ item, index }) => renderItem(item, index)}
            keyExtractor={(item) => item.id.toString()}
          />
          
          {/* Padding bottom */}
          <View style={{ height: 160 }} />

        </SafeAreaView>
      </View>
    </>
  );
}

const styles = {
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    height: height - 136,
    width: width,
    backgroundColor: "#fff",
  },
  noData: {
    flexDirection: "column",
    alignItems: "center",
    marginTop: 60,
  },
  empty: {
    width: width * 0.2,
    height: width * 0.2,
  },
  toast: {
    backgroundColor: "#000",
  },
  buttonsView: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingHorizontal: 20,
    justifyContent: "space-between",
  },
  actionSheetView: {
    width: width,
    padding: 20,
  },
  actionSheetDivider: {
    width: width - 40,
    height: 0.1,
    opacity: 0.3,
    borderStyle: "dashed",
    borderWidth: 0.6,
    borderRadius: 1,
    marginBottom: 32,
  },
  actionSheetButton: {
    width: width - 40,
    justifyContent: "space-between",
    paddingVertical: 12,
  },
  actionSheetTitle: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 24,
    marginBottom: 16,
  },
  actionSheetConfirm: {
    width: width - 40,
    height: 60,
    marginTop: 32,
    backgroundColor: "#4675ff",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  actionSheetConfirmDisabled: {
    width: width - 40,
    height: 60,
    marginTop: 32,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#00000080",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  actionSheetConfirmText: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 16,
    color: "#FFF",
    marginTop: 4,
  },
  actionSheetConfirmTextDisabled: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: 16,
    color: "#00000080",
    marginTop: 4,
  },
  border: {
    width: width,
    height: 1,
    backgroundColor: "#00d60e",
  },
  separator: {
    width: width - 40,
    height: 0.3,
    borderWidth: 1,
    borderColor: "#00000060",
    borderStyle: "dashed",
    borderRadius: 1,
    marginLeft: 20,
    marginBottom: 32,
  },
  errorBanner: {
    position: "absolute",
    left: 20,
    width: width - 40,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: "#000",
    textAlign: "center",
    padding: 28,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#fc1c0d",
    backgroundColor: "#fc9992",
    marginBottom: 32,
    zIndex: 1234,
    top: 20,
  },
};

export default InProgress;
